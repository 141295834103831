import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "../../../others/services/authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import { isAfter, isBefore, isEqual } from "date-fns";
import { GeneralService } from "../../../services/general.service";
import "jquery";
import { KeyMetricsService } from "src/app/others/services/key-metrics.service";

declare let $: any;

@Component({
  selector: "app-performance",
  templateUrl: "./performance.component.html",
  styleUrls: ["./performance.component.scss"],
})
export class PerformanceComponent implements OnInit {

  @ViewChild("modalView") viewModal;

  performanceList: any = [];
  yearlyPerformanceList: any = [];
  searchedPerformanceList: any = [];
  specimenList: any = [];
  clusters: any = [];
  selectedSpecimen: any;

  singleCurrency = "";
  currencyNme: any;
  currencySym: any;
  currencyBase: any;
  companyData: any;
  p = 0;
  detailPage = 0;
  searchVal = false;
  loading = false;
  newStartDate: any;
  newEndDate: any;
  allTime: string = 'All Time';

  fromDate: any;
  toDate: any;
  year = new Date().getFullYear();
  getAllServiceClusters: any;
  getAllBilling: any;

  detailedItem: any = [];
  calculatedTotalArreas: number;
  calculatedTotalBilled: any;
  calculatedTotalPaid: any;
  tab= "pathology";
  dtOptions: DataTables.Settings={};
 permissions: any = [];

  constructor(
    public auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private generalService: GeneralService,
    private keyMetrics: KeyMetricsService
  ) {}

  ngOnInit() {

    this.getPermissions();
    this.getPerformances();
    this.singleCurrency = this.userArray(sessionStorage.getItem("currency"));
    this.currencyNme = this.singleCurrency[0];
    this.currencySym = this.singleCurrency[1];
    this.currencyBase = this.singleCurrency[2];
    this.getComProfile();
    this.getinfomodal();



  }
 // Get user permissions
 getPermissions() {
  this.permissions = {
    performance: {
      view: false
    },
  };

  this.auth
    .get("user_permissions/" + sessionStorage.getItem("userID")).toPromise()
    .then(
      (response: any) => {
        this.permissions = response.permissions.permissions[0];
      },
      (error: any) => {

      }
    );
}
  //get api get data base on radiology and pathology

  userArray(str) {
    return str.trim().split(",");
  }

  getinfomodal(): void {
    const infoIcon = document.querySelector('#info-icon') as HTMLElement;
    const infoModal = document.querySelector('#info-modal') as HTMLElement;

    if (!infoIcon || !infoModal) {
    //  console.error('Could not find info-icon or info-modal elements');
      return;
    }

    infoIcon.addEventListener('mouseenter', () => {
      // console.log('Showing modal');
      infoModal.style.display = 'block';
    });

    infoIcon.addEventListener('mouseleave', () => {
      // console.log('Hiding modal');
      infoModal.style.display = 'none';
    });
    // console.log(this.getinfomodal)
  }



  getPerformances() {
    // this.spinner.show();
    this.auth.get("item_performance").subscribe((response: any) => {
      // console.log(response);
      this.getAllServiceClusters = response['service_cluster'];
      // console.log(this.getAllServiceClusters)
      this.getAllBilling = response['billing']
      // console.log(this.getAllBilling)
      this.getIndexedData()

      const a = [];
        // console.log("r",
        // response.form_name="Wilms Tumor,Ovarian Cancer, Prostate Turp, Prostate Cancer, Nephrectomy, Mastectomy, Laryngectomy, General Specimen, General Biopsy, Gastrectomies, Endometrial, Colon Specimen, Cervical Cancer, Breast Core Biopsy, Nongynaecytology, Prostatectomy, Leiomyomata, Immunohistochemistry Special Stains, Pap Request, Testecular Biopsy, Ultra Sound Ostetrics, Ultra Sound Obstetrics Report Twin, General Radiology Specimen, Ultra Sound Obstetrics Report Anomally, Cervical Spine, Right Shoulder Joint, Plain Radiology Pelvis, General Cancer Biopsies, Renal Core Icision Biopsy ")

      for (const res of response) {
        a.push(...res);
      }

      this.searchedPerformanceList = this.performanceList = a;

      this.getServiceCluster();
      this.getSpecimenList();
    });
    this.spinner.hide();
  }

  onTabChange(tab: string) {
    if (tab !== this.tab) {
      this.tab = tab;
      // console.log(this.tab)
    //   this.keyMetrics.getCount(this.tab)
    //   this.keyMetrics.keyMetrics.subscribe({
    //   next: (response) => {
    //       console.log(response)
    //   }
    // })
      this.clear();

        // //get api get data base on radiology, pathology and clinical lab
      this.auth.get(`item_performance?${tab}=${tab}&start_date=${this.newStartDate}&end_date=${this.newEndDate}`).subscribe({
        next:(response) =>{
          // console.log('response', response)
          this.detailedItem = []
          this.getAllServiceClusters = response['service_cluster'];
           this.getAllBilling = response['billing']
            // this.keyMetrics.getCount(this.getAllBilling)
            // this.getKeyMetricsCount()


          this.getIndexedData();
        },
        error:(error) =>{
          console.log('error', error)
        }
      })

    }
  }

  getKeyMetricsCount() {
    this.keyMetrics.keyMetrics.subscribe({
      next: (response) => {
          // console.log(response)
      }
    })

  }

  getIndexedData() {
    let arreas = 0;
    let amount_paid = 0;
    let total_amount = 0;
    let cases = 0;
    let newData = {};
    let all = []
    for(let i = 0; i < this.getAllBilling.length; i++) {
      // console.log(this.getAllBilling[i])
      // console.log(this.getAllBilling[i][0]?.form_name)
      arreas = this.getAllBilling[i]?.map((a) => a.arreas).filter((b) => b !== undefined)?.reduce((c, d) => c + d, 0)
      cases = this.getAllBilling[i]?.length
      amount_paid = this.getAllBilling[i]?.map((a) => a.amount_paid).filter((b) => b !== undefined)?.reduce((c, d) => c + d, 0)
      total_amount = this.getAllBilling[i]?.map((a) => a.total_amount).filter((b) => b !== undefined)?.reduce((c, d) => c + d, 0)
      // console.log(arreas)
      // console.log(amount_paid)
      // console.log(total_amount)
      // console.log()
      if(this.getAllBilling[i]) {
        newData = {
          form_name: this.getAllBilling[i][0]?.form_name,
          arreas,
          cases,
          amount_paid,
          total_amount,
          spec_details: this.getAllBilling[i]
        }
      }

      //  console.log('newData', newData)
      this.detailedItem.push(newData)

      // console.log('all total data',arreas, amount_paid, total_amount, this.getAllBilling[i][0].form_name)
      //  console.log(this.detailedItem)

    }

    // this.detailedItem = all

    // console.log('detailedPage',all)
    //  console.log('detailedPage',this.detailedItem)
    this.getTotalDisplay();
  }

  getTotalDisplay() {
    // console.log('detailedPage',this.detailedItem)
    //[170, 400, 19990, 5000]
   const arreas = this.detailedItem.map((a) =>  a.arreas)

   .reduce((b, c) => b + c, 0)

  //  console.log(arreas)
   this.calculatedTotalArreas = arreas

  //  total amount

const billed = this.detailedItem.map((a) => a.total_amount)
    .reduce((b, c) => b  + c, 0)

    // console.log(billed)
   this.calculatedTotalBilled = billed

const paid = this.detailedItem.map((a) => a.amount_paid)
    .reduce((b, c) => b  + c, 0)

    // console.log(paid)
   this.calculatedTotalPaid = paid

    // amount paid
  }

  getSpecimenList() {
    const lists = this.searchedPerformanceList;
    const a = [];
    const b = [];

    for (const list of lists) {
      const specimen = list.form_name;
      if (!a.includes(specimen)) {
        const reports = lists.filter((ele) => ele.form_name === specimen);
        b.push({ specimen, reports });
        a.push(specimen);
      }
    }

    this.specimenList = b;
    // this.getCorrectSpeci1()
  }
/* THIS CODE IS TO REPLACE WORNG NAMES SPELT IN THE PERFORMANCE FORM */
// {
// // getCorrectSpeci1() {
// //   let index = 21; // the index of the object to update
// //   let oldObj = this.specimenList[index]; // get the old object
// //   let newObj = { ...oldObj, specimen: 'General Radiology Specimen' }; // create a new object with updated property
// //   this.specimenList.splice(index, 1, newObj); // replace the old object with the new one
// // }

  /**
   * It filters the staffList array to only include the elements that have a created_at date that is equal to or after the
   * from date and equal to or before the to date
   * @param {Event} $event - Event - The event object that is passed to the function when the form is submitted.
   */
  onIntervalSubmit($event: Event) {
    $event.preventDefault();
    const fromVal = $("#from").val();
    const toVal = $("#to").val();

    if (fromVal !== "" && toVal !== "") {
      this.searchVal = true;
      this.loading = true;

      /* Converting the date to a string and then converting it back to a date. */
      const from = new Date(new Date(fromVal).toDateString());
      const to = new Date(new Date(toVal).toDateString());

      this.fromDate = from.toDateString();
      this.toDate = to.toDateString();

      /* Filtering the tatList array to only include the elements that have a created_at date that is equal to or after the
     from date and equal to or before the to date. */
      this.searchedPerformanceList = this.performanceList.filter((ele) => {
        const createdAt = new Date(new Date(ele.created_at).toDateString());
        return (
          (isEqual(from, createdAt) || isAfter(createdAt, from)) &&
          (isEqual(to, createdAt) || isBefore(createdAt, to))
        );
      });
      this.getSpecimenList();
      this.getServiceCluster();
      this.loading = false;
    } else {
      this.generalService.emptyDateFieldError();
    }
  }

  /**
   * The function clears the search fields and resets the search results to the original list
   */
  // clear() {
  //   $("#to").val("");
  //   $("#from").val("");
  //   this.searchedPerformanceList = this.yearlyPerformanceList;
  //   this.searchVal = false;
  //   this.getSpecimenList();
  //   this.getServiceCluster();
  //   // this.changeOrder();
  // }
  
  clear() {
    // $("newStartDate")("");
    // $("newEndDate")("");
    this.newStartDate = (" ")
    this.newEndDate = (" ")
    this.searchVal = false;
    // this.searchedPerformanceList = this.yearlyPerformanceList;
    this.getInputDate();
    this.getTotalDisplay();
    this.getAllServiceClusters();
    this.detailedItem();
    // this.changeOrder();
  }

  /**
   * It takes an array of objects, loops through the array, and adds the value of the total_amount property of each object
   * to a variable
   * @param {any} lists - any - this is the array of objects that you want to get the total amount from.
   * @returns The total amount of all the lists.
   */
  getBilled(lists: any) {
    let a = 0;
    for (const list of lists) {
      a += list.total_amount;
    }

    return a;
  }

  /**
   * It takes an array of objects, loops through the array, and adds the amount_paid property of each object to a variable
   * @param {any} lists - any - this is the array of objects that you want to get the total of.
   * @returns The total amount paid for all the lists.
   */
  getPaid(lists: any) {
    let a = 0;
    for (const list of lists) {
      a += list.amount_paid;
    }

    return a;
  }

  /**
   * It takes an array of objects, loops through the array, and adds the value of the arreas property of each object to a
   * variable
   * @param {any} lists - any - this is the array of objects that we are going to loop through.
   * @returns The total amount of arrears for all the lists.
   */
  getArrears(lists: any) {
    let a = 0;
    for (const list of lists) {
      a += list.arreas;
    }

    return a;
  }

  view(selected) {
    this.selectedSpecimen = selected;

    this.viewModal.show();
  }

  /**
   * This function is used to get the company profile data from the database
   */
  getComProfile() {
    // this.spinner.show();
    this.auth.get("facility_profile").subscribe(
      (response: any) => {
        if (response.company !== null) {
          this.companyData = response.company;
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  /**
   * We're creating a new div element, appending it to the body, copying the contents of the element we want to print into
   * the new div, printing the new div, and then removing the new div from the body
   */
  printElement() {
    const elem = document.getElementById("contentToPrint");
    const domClone = elem.cloneNode(true);

    const $printSection = document.createElement("div");
    $printSection.style.padding = "15px 5px";
    $printSection.id = "printSection";
    document.body.appendChild($printSection);

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
    $printSection.style.padding = "0px";
    $printSection.innerHTML = "";
  }

  /**
   * It takes a list of objects, and returns a list of objects with the same properties, but with the values of the
   * properties calculated from the original list
   */
   getServiceCluster() {
    const a = [];
    const b = [];
    // console.log()

    // I had to comment this
    // this.searchedPerformanceList.forEach((item) => {
    //   console.log("total amount", item.total_amount)
    //   console.log("total arrears", item.arrears)
    //   console.log("amount paid",item.amount_paid )
    // })

    // const totalA = this.searchedPerformanceList
    // .filter((a) => a.total_amount != null)
    // .map((b) => b.total_amount)
    // .reduce((c, d) => c + d )
    // console.log('totalA', totalA)

    //  const totalA = this.searchedPerformanceList
    //  .filter((a) => a.service_type.includes("Gynae Cytology"))
    //  console.log(totalA)

    // console.log("s",this.searchedPerformanceList)
    for (const search of this.searchedPerformanceList) {

        const service = JSON.parse(search?.service_type);

        if(service && service?.service_type !== null){

        if(a.includes(service[0].service_type)){

          for(let serve of b){

            if(serve.cluster == service[0].service_type){
              serve.billed += search.total_amount;
              serve.paid += search.amount_paid
              serve.arrears += search.arreas
            }
          }
          // console.log("oo",b)
        }else{
          // console.log("boo",service)
          b.push({
            cluster: service[0].service_type,
            billed: search.total_amount,
            paid: search.amount_paid,
            arrears: search.arreas,
          });
          a.push(service[0].service_type);
          // console.log("boo",service)
        }
      }

      // }

    }
    this.clusters = b;
    // console.log("c",this.clusters)
  }

  getInputDate() {
    if (this.newStartDate && !this.newEndDate) {
      this.searchVal = true;
      this.auth.get(`item_performance?start_date=${this.newStartDate}&${this.tab}=${this.tab}`).subscribe({
        next: (response: any) => {
          console.log("Data from API", response);
          this.detailedItem = [];
          this.getAllServiceClusters = response['service_cluster'];
          this.getAllBilling = response['billing'];
          console.log("New Data", this.getAllServiceClusters);
          console.log("New Billing Data", this.getAllBilling);
          this.getIndexedData();
        }
      });
      console.log("New Start Date", this.newStartDate);

    } else if (this.newEndDate && !this.newStartDate) {
      this.searchVal = true;
      this.auth.get(`item_performance?end_date=${this.newEndDate}&${this.tab}=${this.tab}`).subscribe({
        next: (response: any) => {
          console.log("Data from API", response);
          this.detailedItem = [];
          this.getAllServiceClusters = response['service_cluster'];
          this.getAllBilling = response['billing'];
          console.log("New Data", this.getAllServiceClusters);
          console.log("New Billing Data", this.getAllBilling);
          this.getIndexedData();
        }
      });
      console.log("New End Date", this.newEndDate);

    } else if (this.newStartDate && this.newEndDate) {
      this.searchVal = true;
      this.auth.get(`item_performance?start_date=${this.newStartDate}&end_date=${this.newEndDate}&${this.tab}=${this.tab}`).subscribe({
        next: (response: any) => {
          console.log("Data from API", response);
          this.detailedItem = [];
          this.getAllServiceClusters = response['service_cluster'];
          this.getAllBilling = response['billing'];
          console.log("New Data", this.getAllServiceClusters);
          console.log("New Billing Data", this.getAllBilling);
          this.getIndexedData();
        }
      });
      console.log("New Start Date", this.newStartDate);
      console.log("New End Date", this.newEndDate);
    } else {
      // Handle the case when start date or end date is missing.
      console.log("Please enter both start date and end date.");
    }
  }
  


}


