<div  *ngIf="permissions.receivables.view" class="row mb-4 align-items-center" style="margin-top: -130px">
  <div class="col-lg-6">
    <h3 class="">Receivables</h3>
  </div>
</div>

<div class="card" *ngIf="permissions.receivables.view">
  <div class="card-header py-3">
    <div class="row">
      <div class="col">
        <h4>Interval</h4>
      </div>
      <div [ngClass]="searchVal ? 'd-none' : 'd-flex-inline'" class="col text-right">
        <p class="mb-0 small">Current Data</p>
        <h5 class="mr-0">{{month}} {{year}}</h5>
      </div>
      <div [ngClass]="searchVal ? 'd-flex-inline' : 'd-none'" class="col text-right">
        <button (click)="clear()" class="btn btn-danger btn-sm"
                type="button">
          Clear
        </button>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="permissions.receivables.view">
    <form (submit)="onSearchSubmit($event)" action="">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="from" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="from">From:</label>
        </div>
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="to" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="to">To:</label>
        </div>
        <div class="col-md-12 col-sm-12 d-flex justify-content-center">
          <button [disabled]="false"
                  class="btn btn-success"
                  style="padding:10px 15%;"
                  type="submit">
            <!-- <i class="fa fa-spinner fa-pulse" *ngIf="loading"></i> -->
            <span *ngIf="this.loading" class="spinner-border spinner-border-sm" role="status"></span>
            Search<span *ngIf="this.loading" class="load-text">ing...</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row" *ngIf="permissions.receivables.view">
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Total Reports</h5>
        <hr>
        <h5>{{searchRecList.length}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6"*ngIf="permissions.receivables.view">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Patients</h5>
        <hr>
        <h5>{{patientList.length}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf="permissions.receivables.view">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Specimens</h5>
        <hr>
        <h5>{{specimenList.length}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf="permissions.receivables.view">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Facilities</h5>
        <hr>
        <h5>{{facilityList.length}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf="permissions.receivables.view">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Total Billed</h5>
        <hr>
        <h5> {{currencyBase}} {{totalBilled | number: "1.2"}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf="permissions.receivables.view">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Total Paid</h5>
        <hr>
        <h5> {{currencyBase}} {{totalPaid | number: "1.2"}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6" *ngIf="permissions.receivables.view">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Total Arrears</h5>
        <hr>
        <h5> {{currencyBase}} {{totalArrears | number: "1.2"}}</h5>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="permissions.receivables.view">
  <div class="card-header py-3">
    <div class="row">
      <div class="col">
        <h4>Details</h4>
      </div>
      <div class="col">
        <div style="float:right;">
          <button (click)="printElement()" class="btn btn-sm btn-success" type="button">Print
          </button>&nbsp;
          <button (click)="csvMe()" class="btn btn-sm btn-secondary" type="button">Export
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <table class="table table-bordered table-striped mb-0" id="report-table">
      <thead>
      <tr>
        <!-- <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> -->
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Date</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Patient</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">ReportID</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Facility</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Billed</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Paid</th>
        <th class="cursor" style="padding-left: 10px; padding-right: 10px">Arrears
          </th>
      </tr>
      </thead>
      <tbody>
      <!-- <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> -->
      <tr *ngFor="let rec of searchRecList
                            | filter: searchText
                            | paginate: { itemsPerPage: 10, currentPage: p };">
        <th>{{ rec.created_at | date: "MMM d, y":"GMT" }} at {{ rec.created_at | date: "h:mm a":"GMT" }}</th>
        <td>{{rec.patient_name}}</td>
        <td>{{rec.clinical_module_items[0].pathology_number}}</td>
        <td>{{rec.clinical_module_items[0].requesting_facility}}</td>
        <td> {{currencyBase}} {{rec.total_amount | number: "1.2"}}</td>
        <td> {{currencyBase}} {{rec.amount_paid ? rec.amount_paid : 0 | number: "1.2"}}</td>
        <th> {{currencyBase}} {{rec.arreas | number: "1.2"}}</th>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer pb-0">
    <pagination-controls
      (pageBoundsCorrection)="p = $event"
      (pageChange)="p = $event"
      autoHide="false"
      directionLinks="true"
      maxSize="9"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: right"
    >
    </pagination-controls>
  </div>

  <div class="d-none">
    <div id="contentToPrint" #contentToPrint>
      <div class="row mb-4">
        <div class="col-sm-12">
          <img *ngIf="companyData?.company_logo !== null" src="{{ companyData?.company_logo }}"
               style="width: 100%; height: 160px;" alt="">
        </div>
      </div>
      <div class="">
        <h4 class="">Receivables</h4>
        <p *ngIf="searchVal" class="mt-0">From: {{fromDate}} - To: {{toDate}}</p>
        <hr>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="card mb-0">
            <div class="card-body text-center">
              <h5 class="">Total Reports</h5>
              <hr>
              <h5>{{searchRecList.length}}</h5>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card mb-0">
            <div class="card-body text-center">
              <h5 class="">Patients</h5>
              <hr>
              <h5>{{patientList.length}}</h5>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card mb-0">
            <div class="card-body text-center">
              <h5 class="">Specimens</h5>
              <hr>
              <h5>{{specimenList.length}}</h5>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card mb-0">
            <div class="card-body text-center">
              <h5 class="">Facilities</h5>
              <hr>
              <h5>{{facilityList.length}}</h5>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card">
            <div class="card-body text-center">
              <h5 class="">Total Billed</h5>
              <hr>
              <h5> {{currencyBase}} {{totalBilled | number: "1.2"}}</h5>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card">
            <div class="card-body text-center">
              <h5 class="">Total Paid</h5>
              <hr>
              <h5> {{currencyBase}} {{totalPaid | number: "1.2"}}</h5>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="card">
            <div class="card-body text-center">
              <h5 class="">Total Arrears</h5>
              <hr>
              <h5> {{currencyBase}} {{totalArrears | number: "1.2"}}</h5>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-bordered table-striped" id="report-table">
        <thead>
        <tr>
          <!-- <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> -->
          <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Date</th>
          <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Patient</th>
          <th style="padding-left: 10px; padding-right: 10px; text-transform: none">ReportID</th>
          <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Facility</th>
          <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Billed</th>
          <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Paid</th>
          <th style="padding-left: 10px; padding-right: 10px">Arrears
            </th>
        </tr>
        </thead>
        <tbody>
        <!-- <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> -->
        <tr *ngFor="let rec of searchRecList">
          <th>{{ rec.created_at | date: "MMM d, y":"GMT" }} at {{ rec.created_at | date: "h:mm a":"GMT" }}</th>
          <td>{{rec.patient_name}}</td>
          <td>{{rec.clinical_module_items[0].pathology_number}}</td>
          <td>{{rec.clinical_module_items[0].requesting_facility}}</td>
          <td> {{currencyBase}} {{rec.total_amount | number: "1.2"}}</td>
          <td> {{currencyBase}} {{rec.amount_paid ? rec.paid : 0 | number: "1.2"}}</td>
          <th> {{currencyBase}} {{rec.arreas | number: "1.2"}}</th>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
