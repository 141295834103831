<div  *ngIf="permissions.discounts.view " class="row mb-4 align-items-center" style="margin-top: -130px">
  <div class="col-lg-6">
    <h3 class="">Discounts</h3>
  </div>
</div>

<div class="card" *ngIf="permissions.discounts.view ">
  <div class="card-header py-3">
    <div class="row">
      <div class="col">
        <h4>Interval</h4>
      </div>
      <div [ngClass]="searchVal ? 'd-flex-inline' : 'd-none'" class="col text-right">
        <button (click)="clear()" class="btn btn-danger btn-sm"
                type="button">
          Clear
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <form (submit)="onSearchSubmit($event)" action="">
      <div class="row">
        <div class="col-md-4 col-sm-4">
          <ng-select (change)="onChangeFacility($event)" [(ngModel)]='facilities' [closeOnSelect]="true" [items]="searchFacList"
                     [multiple]="false" [searchable]="true" bindLabel="facility_full_name" class="ng-disc-select"
                     id="tumourLeft">
          </ng-select>
          <label class="" for="">Select facility</label>
        </div>
        <div class="col-md-4 col-sm-4">
          <input class="form-control" id="from" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="from">From:</label>
        </div>
        <div class="col-md-4 col-sm-4">
          <input class="form-control" id="to" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="to">To:</label>
        </div>
        <div class="col-md-12 col-sm-12 d-flex justify-content-center">
          <button [disabled]="false"
                  class="btn btn-success"
                  style="padding:10px 15%;"
                  type="submit">

            <span *ngIf="this.loading" class="spinner-border spinner-border-sm" role="status"></span>
            Search<span *ngIf="this.loading" class="load-text">ing...</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row justify-content-center" *ngIf="permissions.discounts.view">
  <!-- <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Average Discount %</h5>
        <hr>
        <h5>{{getAvgDiscPer(searchDiscList)}}%</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Average Discount Amount</h5>
        <hr>
        <h5>{{ currencyBase }} {{getAvgDisc(searchDiscList) | number: "1.2"}}</h5>
      </div>
    </div>
  </div> -->
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
        <div class="card-header bg-billed text-center border-top-radius" style="background-color: rgb(37, 37, 233);">
        <h5 class="">Total Discount Amount</h5>
        </div>
        <div class="card-body text-center">
        <h5>{{ currencyBase }} {{getTotalDisc(searchDiscList) | number: "1.2"}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header bg-billed text-center border-top-radius" style="background-color: rgb(25, 150, 25);">
      <h5 class="">Total Discounted Bills</h5>
      </div>
      <div class="card-body text-center">
        <h5>{{ currencyBase }}{{generalServices.getBilled(searchDiscList) | number: "1.2"}}</h5>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="permissions.discounts.view">
  <div class="card-header">
    <div class="row">
      <div class="col">
        <h4>Details</h4>
      </div>
      <div class="col">
        <div style="float:right;">
          <button (click)="printElement()" class="btn btn-sm btn-success" type="button">Print
          </button>&nbsp;
          <button (click)="csvMe(searchDiscList)" class="btn btn-sm btn-secondary" type="button">Export
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <table class="table table-bordered table-striped" id="report-table">
      <thead>
      <tr>
        <!-- <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> -->
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Date</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Patient</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Facility</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">ReportID</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Service</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Billed</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Discount GHS</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Discount %</th>
        <!--      <th (click)="changeOrder()" class="cursor" style="padding-left: 10px; padding-right: 10px">Arrears-->
        <!--        <span [ngClass]="desc ? 'fa-arrow-circle-o-up' : 'fa-arrow-circle-o-down'" class="fa "></span></th>-->
      </tr>
      </thead>
      <tbody>
      <!-- <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> -->
      <tr *ngFor="let disc of searchDiscList | paginate: { itemsPerPage: 10, currentPage: p };">
        <th>{{ disc.created_at | date: "MMM d, y":"GMT" }} at {{ disc.created_at | date: "h:mm a":"GMT" }}</th>
        <td>{{disc.patient_name}}</td>
        <td>{{disc.requesting_facility}}</td>
        <td>{{disc.pathology_number}}</td>
        <td>{{disc.form_name}}</td>
        <td *ngIf="disc.total_amount !== 'NaN'; else nanValue">
          ₵{{disc.total_amount | number: "1.2"}}

        </td>
        <ng-template #nanValue>
          <td>
            ₵0.00

          </td>
        </ng-template>
        <td *ngIf="disc.discountAmt !== 'NaN'; else nanDiscount">
          {{ currencyBase }} {{disc.discountAmt.toFixed(2)}}
        </td>
        <ng-template #nanDiscount>
          <td>
            ₵0.00

          </td>
        </ng-template>

        <td  *ngIf="disc.discount !== 'NaN%'; else nanDiscountPer">
           {{disc.discount}}
        </td>
        <ng-template #nanDiscountPer>
          <td>
            0%

          </td>
        </ng-template>

      </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <pagination-controls
      (pageBoundsCorrection)="p = $event"
      (pageChange)="p = $event"
      autoHide="false"
      directionLinks="true"
      maxSize="9"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: right"
    >
    </pagination-controls>
  </div>
</div>

<div class="d-none" *ngIf="permissions.discounts.view">
  <div id="printContent" #printContent>
    <div class="row mb-4">
      <div class="col-sm-12">
        <img *ngIf="companyData?.company_logo !== null" src="{{ companyData?.company_logo }}"
             style="width: 100%; height: 160px;" alt="">
      </div>
    </div>
    <div class="">
      <h4 class="">Discounts</h4>
      <p *ngIf="searchVal" class="mt-0">From: {{fromDate}} - To: {{toDate}}</p>
      <hr>
    </div>
    <div class="row">
      <!-- <div class="col-3">
        <div class="card">
          <div class="card-body text-center">
            <h5 class="">Average Discount %</h5>
            <hr>
            <h5>{{getAvgDiscPer(searchDiscList)}}%</h5>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-3">
        <div class="card">
          <div class="card-body text-center">
            <h5 class="">Average Discount Amount</h5>
            <hr>
            <h5>{{ currencyBase }} {{getAvgDisc(searchDiscList) | number: "1.2"}}</h5>
          </div>
        </div>
      </div> -->
      <div class="col-3">
        <div class="card">
          <div class="card-body text-center">
            <h5 class="">Total Discount Amount</h5>
            <hr>
            <h5>{{ currencyBase }} {{getTotalDisc(searchDiscList) | number: "1.2"}}</h5>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <div class="card-body text-center">
            <h5 class="">Total Billed</h5>
            <hr>
            <h5>{{ currencyBase }}{{generalServices.getBilled(searchDiscList) | number: "1.2"}}</h5>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-bordered table-striped" id="report-table">
      <thead>
      <tr>
        <!-- <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> -->
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Date</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Patient</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">ReportID</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Service</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Billed</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Discount</th>
        <!--      <th (click)="changeOrder()" class="cursor" style="padding-left: 10px; padding-right: 10px">Arrears-->
        <!--        <span [ngClass]="desc ? 'fa-arrow-circle-o-up' : 'fa-arrow-circle-o-down'" class="fa "></span></th>-->
      </tr>
      </thead>
      <tbody>
      <!-- <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> -->
      <tr *ngFor="let disc of searchDiscList">
        <th>{{ disc.created_at | date: "MMM d, y":"GMT" }} at {{ disc.created_at | date: "h:mm a":"GMT" }}</th>
        <td>{{disc.patient_name}}</td>
        <td>{{disc.pathology_number}}</td>
        <td>{{disc.form_name}}</td>
        <td>₵{{disc.total_amount | number: "1.2"}}</td>
        <td>{{ currencyBase }} {{disc.discountAmt.toFixed(2)}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


