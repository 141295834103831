import { Injectable } from "@angular/core";

export interface NavigationItem {
  id: string;
  title: string;
  type: "item" | "collapse" | "group";
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
    icon?: string;
    count?: any;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

@Injectable()
export class NavigationItem {
  public get() {
    const subscription_data = JSON.parse(
      sessionStorage.getItem("subscription_data")
    );

    var activatePath = false;
    var activateGP = false;
    var activatePathGP = false;
    var activePathRD = true;
    var activeRd = false;
    var activateRadGP = false;

   if (
      subscription_data !== null &&
      ((subscription_data.plan.includes("General Practice") &&
        subscription_data.plan.includes("Pathology")) ||
        subscription_data.plan.includes("Trial Period"))
    ) {
      activatePathGP = true;
      // return NavigationItems;
    } else if (
      subscription_data !== null &&
      subscription_data.plan.includes("General Practice")
    ) {
      activateGP = true;
      // return GeneralPracticeOnlyNavItems;
    } else if (
      subscription_data !== null &&
      subscription_data.plan.includes("Pathology")
    ) {
      activatePath = true;
      // return PathologyOnlyNavItems;
    }
    const PATHOLOGY_NAV_ITEMS = {
      id: "pathology",
      title: "Pathology",
      type: "collapse",
      url: "/dashboard/clinical-modules/pathology",
      icon: "feather icon-copy",
      children: [
        {
          id: "pathology-home",
          title: "Home",
          type: "item",
          url: "/dashboard/clinical-modules/pathology",
        },
        {
          id: "colon-specs",
          title: "Colon",
          type: "item",
          url: "/clinical-modules/pathology/colon-specs",
        },
        {
          id: "colon-specs",
          title: "Colon",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/colon-specs",
          children: [
            {
              id: "colon-specs",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/colon-specs/amendments",
            },
            {
              id: "colon-specs",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/colon-specs/archive",
            },
          ],
        },
        {
          id: "gastrectomy",
          title: "Gastrectomy",
          type: "item",
          url: "/clinical-modules/pathology/gastrectomy",
        },
        {
          id: "gastrectomy",
          title: "Gastrectomy",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/gastrectomy",
          children: [
            {
              id: "gastrectomy",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/gastrectomy/amendments",
            },
            {
              id: "gastrectomy",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/gastrectomy/archive",
            },
          ],
        },
        {
          id: "hysterectomy",
          title: "Hysterectomy",
          type: "collapse",
          children: [
            {
              id: "cervical-cancer",
              title: "Cervical Cancer",
              type: "item",
              url: "/clinical-modules/pathology/hysterectomy/cervical-cancer",
            },
            {
              id: "cervical-cancer",
              title: "Cervical Cancer",
              type: "collapse",
              hidden: true,
              url: "/clinical-modules/pathology/hysterectomy/cervical-cancer",
              children: [
                {
                  id: "cervical-cancer",
                  title: "Amendments",
                  type: "item",
                  url: "/clinical-modules/pathology/hysterectomy/cervical-cancer/amendments",
                },
                {
                  id: "cervical-cancer",
                  title: "Trash",
                  type: "item",
                  url: "/clinical-modules/pathology/hysterectomy/cervical-cancer/archive",
                },
              ],
            },
            {
              id: "endometrial-cancer",
              title: "Endometrial Cancer",
              type: "item",
              url: "/clinical-modules/pathology/hysterectomy/endometrial-cancer",
            },
            {
              id: "endometrial-cancer",
              title: "Endometrial Cancer",
              type: "collapse",
              hidden: true,
              url: "/clinical-modules/pathology/hysterectomy/endometrial-cancer",
              children: [
                {
                  id: "endometrial-cancer",
                  title: "Amendments",
                  type: "item",
                  url: "/clinical-modules/pathology/hysterectomy/endometrial-cancer/amendments",
                },
                {
                  id: "endometrial-cancer",
                  title: "Trash",
                  type: "item",
                  url: "/clinical-modules/pathology/hysterectomy/endometrial-cancer/archive",
                },
              ],
            },
            {
              id: "ovarian-cancer",
              title: "Ovarian Cancer",
              type: "item",
              url: "/clinical-modules/pathology/hysterectomy/ovarian-cancer",
            },
            {
              id: "ovarian-cancer",
              title: "Ovarian Cancer",
              type: "collapse",
              hidden: true,
              url: "/clinical-modules/pathology/hysterectomy/ovarian-cancer",
              children: [
                {
                  id: "ovarian-cancer",
                  title: "Amendments",
                  type: "item",
                  url: "/clinical-modules/pathology/hysterectomy/ovarian-cancer/amendments",
                },
                {
                  id: "ovarian-cancer",
                  title: "Trash",
                  type: "item",
                  url: "/clinical-modules/pathology/hysterectomy/ovarian-cancer/archive",
                },
              ],
            },
          ],
        },
        {
          id: "mastectomy",
          title: "Mastectomy",
          type: "item",
          url: "/clinical-modules/pathology/mastectomy",
        },
        {
          id: "mastectomy",
          title: "Mastectomy",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/mastectomy",
          children: [
            {
              id: "mastectomy",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/mastectomy/amendments",
            },
            {
              id: "mastectomy",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/mastectomy/archive",
            },
          ],
        },
        {
          id: "nephrectomy",
          title: "Nephrectomy",
          type: "collapse",
          children: [
            {
              id: "nephrectomy-main",
              title: "Nephrectomy Main",
              type: "item",
              url: "/clinical-modules/pathology/nephrectomy/nephrectomy-main",
            },
            {
              id: "nephrectomy-main",
              title: "Nephrectomy Main",
              type: "collapse",
              hidden: true,
              url: "/clinical-modules/pathology/nephrectomy/nephrectomy-main",
              children: [
                {
                  id: "nephrectomy-main",
                  title: "Amendments",
                  type: "item",
                  url: "/clinical-modules/pathology/nephrectomy/nephrectomy-main/amendments",
                },
                {
                  id: "nephrectomy-main",
                  title: "Trash",
                  type: "item",
                  url: "/clinical-modules/pathology/nephrectomy/nephrectomy-main/archive",
                },
              ],
            },
            {
              id: "wilms-tumor",
              title: "Wilm`s Tumor",
              type: "item",
              url: "/clinical-modules/pathology/nephrectomy/wilms-tumor",
            },
            {
              id: "wilms-tumor",
              title: "Wilm`s Tumor",
              type: "collapse",
              hidden: true,
              url: "/clinical-modules/pathology/nephrectomy/wilms-tumor",
              children: [
                {
                  id: "wilms-tumor",
                  title: "Amendments",
                  type: "item",
                  url: "/clinical-modules/pathology/nephrectomy/wilms-tumor/amendments",
                },
                {
                  id: "wilms-tumor",
                  title: "Trash",
                  type: "item",
                  url: "/clinical-modules/pathology/nephrectomy/wilms-tumor/archive",
                },
              ],
            },
          ],
        },
        {
          id: "pap-request",
          title: "Pap Request",
          type: "item",
          url: "/clinical-modules/pathology/pap-request",
        },
        {
          id: "pap-request",
          title: "Pap Request",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/pap-request",
          children: [
            {
              id: "pap-request",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/pap-request/amendments",
            },
            {
              id: "pap-request",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/pap-request/archive",
            },
          ],
        },
        {
          id: "general-template",
          title: "General Template",
          type: "item",
          url: "/clinical-modules/pathology/general-template",
        },
        {
          id: "general-template",
          title: "General Template",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/general-template",
          children: [
            {
              id: "general-template",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/general-template/amendments",
            },
            {
              id: "general-template",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/general-template/archive",
            },
          ],
        },
        {
          id: "breast-core-and-incision-biopsies",
          title: "Breast Core and Incision Biopsies",
          type: "item",
          url: "/clinical-modules/pathology/breast-core-and-incision-biopsies",
        },
        {
          id: "breast-core-and-incision-biopsies",
          title: "Breast Core and Incision Biopsies",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/breast-core-and-incision-biopsies",
          children: [
            {
              id: "breast-core-and-incision-biopsies",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/breast-core-and-incision-biopsies/amendments",
            },
            {
              id: "breast-core-and-incision-biopsies",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/breast-core-and-incision-biopsies/archive",
            },
          ],
        },
        {
          id: "general-part-biopsies",
          title: "General Part Biopsies",
          type: "item",
          // badge: {
          //   title: "New",
          //   type: "badge-danger"
          // },
          url: "/clinical-modules/pathology/general-part-biopsies",
        },
        {
          id: "general-part-biopsies",
          title: "General Part Biopsies",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/general-part-biopsies",
          children: [
            {
              id: "general-part-biopsies",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/general-part-biopsies/amendments",
            },
            {
              id: "general-part-biopsies",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/general-part-biopsies/archive",
            },
          ],
          // badge: {
          //   title: 'New',
          //   type: 'badge-danger'
          // }
        },
        {
          id: "prostate-cancer-biopsy",
          title: "Prostate Cancer Biopsy",
          type: "item",
          url: "/clinical-modules/pathology/prostate-cancer-biopsy",
        },
        {
          id: "prostate-cancer-biopsy",
          title: "Prostate Cancer Biopsy",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/prostate-cancer-biopsy",
          children: [
            {
              id: "prostate-cancer-biopsy",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/prostate-cancer-biopsy/amendments",
            },
            {
              id: "prostate-cancer-biopsy",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/prostate-cancer-biopsy/archive",
            },
          ],
        },
        {
          id: "prostate-turp-and-enucleation",
          title: "Prostate Turp & Enucleation",
          type: "item",
          url: "/clinical-modules/pathology/prostate-turp-and-enucleation",
        },
        {
          id: "prostate-turp-and-enucleation",
          title: "Prostate Turp & Enucleation",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/prostate-turp-and-enucleation",
          children: [
            {
              id: "prostate-turp-and-enucleation",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/prostate-turp-and-enucleation/amendments",
            },
            {
              id: "prostate-turp-and-enucleation",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/prostate-turp-and-enucleation/archive",
            },
          ],
        },
        {
          id: "prostate-cancer-radical-prostatectomy",
          title: "Prostate Cancer Radical Prostatectomy",
          type: "item",
          url: "/clinical-modules/pathology/prostate-cancer-radical-prostatectomy",
        },
        {
          id: "prostate-cancer-radical-prostatectomy",
          title: "Prostate Cancer Radical Prostatectomy",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/prostate-cancer-radical-prostatectomy",
          children: [
            {
              id: "prostate-cancer-radical-prostatectomy",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/prostate-cancer-radical-prostatectomy/amendments",
            },
            {
              id: "prostate-cancer-radical-prostatectomy",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/prostate-cancer-radical-prostatectomy/archive",
            },
          ],
        },
        {
          id: "non-gynae-cytology",
          title: "Non Gynae Cytology",
          type: "item",
          url: "/clinical-modules/pathology/non-gynae-cytology",
        },
        {
          id: "non-gynae-cytology",
          title: "Non Gynae Cytology",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/non-gynae-cytology",
          children: [
            {
              id: "non-gynae-cytology",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/non-gynae-cytology/amendments",
            },
            {
              id: "non-gynae-cytology",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/non-gynae-cytology/archive",
            },
          ],
        },
        {
          id: "laryngectomy",
          title: "Laryngectomy",
          type: "item",
          url: "/clinical-modules/pathology/laryngectomy",
        },
        {
          id: "laryngectomy",
          title: "Laryngectomy",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/laryngectomy",
          children: [
            {
              id: "laryngectomy",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/laryngectomy/amendments",
            },
            {
              id: "laryngectomy",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/laryngectomy/archive",
            },
          ],
        },
        {
          id: "immunohistochemistry",
          title: "Immunohistochemistry",
          type: "item",
          url: "/clinical-modules/pathology/immunohistochemistry",
        },
        {
          id: "immunohistochemistry",
          title: "Immunohistochemistry",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/immunohistochemistry",
          children: [
            {
              id: "immunohistochemistry",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/immunohistochemistry/amendments",
            },
            {
              id: "immunohistochemistry",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/immunohistochemistry/archive",
            },
          ],
        },
        {
          id: "immunohistochemistry-special-stains",
          title: "Immunohistochemistry Special Stains",
          type: "item",
          // badge: {
          //   title: "New",
          //   type: "badge-danger"
          // },
          url: "/clinical-modules/pathology/immunohistochemistry-special-stains",
        },
        {
          id: "immunohistochemistry-special-stains",
          title: "Immunohistochemistry Special Stains",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/immunohistochemistry-special-stains",
          children: [
            {
              id: "immunohistochemistry-special-stains",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/immunohistochemistry-special-stains/amendments",
            },
            {
              id: "immunohistochemistry-special-stains",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/immunohistochemistry-special-stains/archive",
            },
          ],
          // badge: {
          //   title: 'New',
          //   type: 'badge-danger'
          // }
        },
        {
          id: "leiomyomata",
          title: "Leiomyomata",
          type: "item",
          // badge: {
          //   title: "New",
          //   type: "badge-danger"
          // },
          url: "/clinical-modules/pathology/leiomyomata",
        },
        {
          id: "leiomyomata",
          title: "Leiomyomata",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/leiomyomata",
          children: [
            {
              id: "leiomyomata",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/pathology/leiomyomata/amendments",
            },
            {
              id: "leiomyomata",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/leiomyomata/archive",
            },
          ],
          // badge: {
          //   title: 'New',
          //   type: 'badge-danger'
          // }
        },
        {
          id: "testicular-biopsy",
          title: "Testicular Biopsy",
          type: "item",

          url: "/clinical-modules/pathology/testicular-biopsy",
        },
        {
          id: "testicular-biopsy",
          title: "Testicular Biopsy",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/testicular-biopsy",
          children: [
            {
              id: "testicular-biopsy",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/pathology/testicular-biopsy/archive",
            },
          ],
        },
        {
          id: "general-cancer-template-biopsies",
          title: "General Cancer Biopsy",
          type: "item",

          url: "/clinical-modules/pathology/general-cancer-template-biopsies",
        },
        {
          id: "general-cancer-template-biopsies",
          title: "General Cancer Biopsy",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/general-cancer-template-biopsies",
          children: [],
        },

        {
          id: "renal-core-incision-biopsies",
          title: "Renal Core & Incision Biopsies",
          type: "item",

          url: "/clinical-modules/pathology/renal-core-incision-biopsies",
        },
        {
          id: "renal-core-incision-biopsies",
          title: "Renal Core & Incision Biopsies",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/renal-core-incision-biopsies",
          children: [],
        },



        {
          id: "ish-template",
          title: "ISH Template",
          type: "item",

          url: "/clinical-modules/pathology/ish-template",
        },
        {
          id: "ish-template",
          title: "ISH Template",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/ish-template",
          children: [],
        },
        {
          id: "thyroidectomy",
          title: "Thyroidectomy",
          type: "item",
          url: "/clinical-modules/pathology/thyroidectomy",
        },
        {
          id: "thyroidectomy",
          title: "Thyroidectomy",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/thyroidectomy",

        },
        {
          id: "medical_liver_core_biopsy",
          title: "Medical Liver Core Biopsy",
          type: "item",
          url: "/clinical-modules/pathology/medical-liver-core-biopsy",
        },
        {
          id: "medical_liver_core_biopsy",
          title: "Medical Liver Core Biopsy",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/medical-liver-core-biopsy",

        },
        {
          id: "hpv-template",
          title: "HPV Template",
          type: "item",
          url: "/clinical-modules/pathology/hpv-template",
        },
        {
          id: "hpv-template",
          title: "HPV Template",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/hpv-template",

        },
        {
          id: "soft tissue tumours",
          title: "Soft Tissue Tumours",
          type: "item",
          url: "/clinical-modules/pathology/soft-tissue-tumours",
        },
        {
          id: "soft tissue tumours",
          title: "Soft Tissue Tumours",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/soft-tissue-tumours",

        },
        {
          id: "appendix-cancer",
          title: "Appendix Excision",
          type: "item",

          url: "/clinical-modules/pathology/appendix-cancer",
        },
        {
          id: "autopsy",
          title: "Autopsy",
          type: "item",
          url: "/clinical-modules/pathology/autopsy",
        },
        {
          id: "appendix-cancer",
          title: "Appendix Excision",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/pathology/appendix-cancer",
          children: [],
        },
      ],
    };

    const GENERAL_PRACTISE_NAV_ITEMS = {
      id: "general-practise",
      title: "General Practise",
      type: "collapse",
      url: "/dashboard/clinical-modules/general-practice",
      icon: "feather icon-file-text",
      children: [
        {
          id: "general-practise-home",
          title: "Home",
          type: "item",
          url: "/dashboard/clinical-modules/general-practice",
        },
        {
          id: "admissions",
          title: "Admissions",
          type: "item",
          url: "/clinical-modules/general-practise/admissions",
        },
        {
          id: "admissions",
          title: "Admissions",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/general-practise/admissions",
          children: [
            {
              id: "general-practise",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/general-practise/admissions/amendments",
            },
            {
              id: "general-practise",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/general-practise/admissions/archive",
            },
          ],
        },
        {
          id: "consultation",
          title: "Consultation",
          type: "item",
          url: "/clinical-modules/general-practise/consultation",
        },
        {
          id: "consultation",
          title: "Consultation",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/general-practise/consultation",
          children: [
            {
              id: "general-practise",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/general-practise/consultation/amendments",
            },
            {
              id: "general-practise",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/general-practise/consultation/archive",
            },
          ],
        },
        {
          id: "clinical-laboratory",
          title: "Clinical Laboratory",
          type: "item",
          url: "/clinical-modules/general-practise/clinical-laboratory",
        },
        {
          id: "clinical-laboratory",
          title: "Clinical Laboratory",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/general-practise/clinical-laboratory",
          children: [
            {
              id: "general-practise",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/general-practise/clinical-laboratory/amendments",
            },
            {
              id: "general-practise",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/general-practise/clinical-laboratory/archive",
            },
          ],
        },
        {
          id: "physical-examination",
          title: "Physical Examination",
          type: "item",
          url: "/clinical-modules/general-practise/physical-examination",
        },
        {
          id: "physical-examination",
          title: "Physical Examination",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/general-practise/physical-examination",
          children: [
            {
              id: "general-practise",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/general-practise/physical-examination/archive",
            },
          ],
        },
        {
          id: "referral-letters",
          title: "Referral Letters",
          type: "item",
          url: "/clinical-modules/general-practise/referral-letters",
        },
        {
          id: "referral-letters",
          title: "Referral Letters",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/general-practise/referral-letters",
          children: [
            {
              id: "general-practise",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/general-practise/referral-letters/amendments",
            },
            {
              id: "general-practise",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/general-practise/referral-letters/archive",
            },
          ],
        },
        {
          id: "prescription",
          title: "Prescription",
          type: "item",
          url: "/clinical-modules/general-practise/prescription",
        },
        {
          id: "medical-certificates",
          title: "Medical Certificates",
          type: "item",
          url: "/clinical-modules/general-practise/medical-certificates",
        },
        {
          id: "medical-certificates",
          title: "Medical Certificates",
          type: "collapse",
          hidden: true,
          url: "/clinical-modules/general-practise/medical-certificates",
          children: [
            {
              id: "general-practise",
              title: "Amendments",
              type: "item",
              url: "/clinical-modules/general-practise/medical-certificates/amendments",
            },
            {
              id: "general-practise",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/general-practise/medical-certificates/archive",
            },
          ],
        },
      ],
    };

    const RADIOLOGY_NAVS = {
      id: "general-practise",
      title: "Radiology",
      type: "collapse",
      url: "/dashboard/clinical-modules/radiology",
      icon: "feather icon-grid",
      // badge: {
      //   title: "Update",
      //   type: "badge-danger",
      // },
      children: [
        {
          id: "radiology",
          title: "PR Lumbar Spine",
          type: "item",
          url: "/clinical-modules/radiology/lumbar-spine",
        },
        // {
        //   id: "radiology",
        //   title: "Both Shoulder Joint",
        //   type: "item",
        //    url:
        //      "/clinical-modules/radiology/both-shoulder-joint",
        // },
        {
          id: "radiology",
          title: "PR Cervical Spine",
          type: "item",
          url: "/clinical-modules/radiology/cervical-spine",
          children:[
            {
              id: "radiology",
              title: "Trash",
              type: "item",
              url: "/clinical-modules/radiology/cervical-spine/archive",
            },
          ]
        },
        {
          id: "radiology",
          title: "Us Obstetrics",
          type: "item",
          url: "/clinical-modules/radiology/ut-obstetrics-report",
        },

        {
          id: "radiology",
          title: "Us Renal Dopplers",
          type: "item",
          url: "/clinical-modules/radiology/us-renal-doppler",
        },

        {
          id: "radiology",
          title: "Us Breast",
          type: "item",
          url: "/clinical-modules/radiology/us-breast",
        },
        // {
        //   id: "radiology",
        //   title: "Ct Chronic Infarct(Non Contrast)",
        //   type: "item",
        //   url: "/clinical-modules/radiology/ct-chronic-infarct",
        // },
        {
          id: "radiology",
          title: "Ct Lumbar Spine",
          type: "item",
          url: "/clinical-modules/radiology/ct-lumbar-spine",
        },
        {
          id: "radiology",
          title: "Ut Paediatric Spine",
          type: "item",
          url: "/clinical-modules/radiology/ut-paediatric-spine",
        },
        // {
        //   id: "radiology",
        //   title: "Ct Ivu Male",
        //   type: "item",
        //   url: "/clinical-modules/radiology/ct-ivu-male",
        // },
        {
          id: "radiology",
          title: "CT Head (Contrast)",
          type: "item",
          url: "/clinical-modules/radiology/ct-head",
        },
        {
          id: "radiology",
          title: "CT Neck",
          type: "item",
          url: "/clinical-modules/radiology/ct-neck",
        },
        {
          id: "radiology",
          title: "CT Hip",
          type: "item",
          url: "/clinical-modules/radiology/ct-hip",
        },
        {
          id: "radiology",
          title: "CT Knee",
          type: "item",
          url: "/clinical-modules/radiology/ct-knee",
        },
        {
          id: "radiology",
          title: "CT Pelvis",
          type: "item",
          url: "/clinical-modules/radiology/ct-pelvis",
        },
        {
          id: "radiology",
          title: "CT Head Non Contrast",
          type: "item",
          url: "/clinical-modules/radiology/ct-head-non-contrast",
        },
        {
          id: "radiology",
          title: "CT Chest",
          type: "item",
          url: "/clinical-modules/radiology/ct-chest",
        },

        {
          id: "radiology",
          title: "CT Thoracic Spine",
          type: "item",
          url: "/clinical-modules/radiology/ct-thoracic-spine",
        },
        {
          id: "radiology",
          title: "CT Intravenous Urography",
          type: "item",
          url: "/clinical-modules/radiology/ct-intravenous-urography",
        },
        {
          id: "radiology",
          title: "CT Abdomen Pelvis Non Contrast",
          type: "item",
          url: "/clinical-modules/radiology/ct-abdomen-pelvis-non-contrast",
        },
        {
          id: "radiology",
          title: "CT Pulmonary Angiography",
          type: "item",
          url: "/clinical-modules/radiology/ct-pulmonary-angiography",
        },
        {
          id: "radiology",
          title: "CT Peripheral Angiography",
          type: "item",
          url: "/clinical-modules/radiology/ct-peripheral-angiography",
        },
        {
          id: "radiology",
          title: "Ct Triple Phase Abdomen",
          type: "item",
          url: "/clinical-modules/radiology/ct-triple-phase-abdomen",
        },
        {
          id: "radiology",
          title: "CT Brain Orbit",
          type: "item",
          url: "/clinical-modules/radiology/ct-brain-orbit",
        },
        {
          id: "radiology",
          title: "CT Cervical Spine",
          type: "item",
          url: "/clinical-modules/radiology/ct-cervical-spine",
        },
        {
          id: "radiology",
          title: "CT Shoulder",
          type: "item",
          url: "/clinical-modules/radiology/ct-shoulder",
        },
        {
          id: "radiology",
          title: "CT Brain Sinus",
          type: "item",
          url: "/clinical-modules/radiology/ct-brain-sinus",
        },
        {
          id: "radiology",
          title: "CT Ankle",
          type: "item",
          url: "/clinical-modules/radiology/ct-ankle",
        },
        {
          id: "radiology",
          title: "PR Ankle Joint",
          type: "item",
          url: "/clinical-modules/radiology/pr-ankle-joint",
        },
        {
          id: "radiology",
          title: "PR Left / Right Joint",
          type: "item",
          url: "/clinical-modules/radiology/pr-lt-rt-elbow-joint",
        },
        {
          id: "radiology",
          title: "CT Ear",
          type: "item",
          url: "/clinical-modules/radiology/ct-ear",
        },
        {
          id: "radiology",
          title: "CT Elbow",
          type: "item",
          url: "/clinical-modules/radiology/ct-elbow",
        },
        {
          id: "radiology",
          title: "PR Thoracic Spine",
          type: "item",
          url: "/clinical-modules/radiology/pr-thoracic-spine",
        },
        // {
        //   id: "radiology",
        //   title: "PR Abdomen Pelvis",
        //   type: "item",
        //   url: "/clinical-modules/radiology/pr-abdomen-pelvis",
        // },
        {
          id: "radiology",
          title: "PR Both Shoulder Joint",
          type: "item",
          url: "/clinical-modules/radiology/pr-both-shoulder-joint",
        },
        {
          id: "radiology",
          title: "PR Both Feet",
          type: "item",
          url: "/clinical-modules/radiology/pr-both-feet",
        },
        {
          id: "radiology",
          title: "PR Both Hands",
          type: "item",
          url: "/clinical-modules/radiology/pr-both-hands",
        },
        {
          id: "radiology",
          title: "PR Abdomen Pelvis",
          type: "item",
          url: "/clinical-modules/radiology/pr-abdomen-pelvis",
        },
        {
          id: "radiology",
          title: "PR Skull",
          type: "item",
          url: "/clinical-modules/radiology/pr-skull",
        },
        {
          id: "radiology",
          title: "PR Elbow Joint",
          type: "item",
          url: "/clinical-modules/radiology/pr-elbow-joint",
        },
        {
          id: "radiology",
          title: "PR Both Wrist",
          type: "item",
          url: "/clinical-modules/radiology/pr-both-wrist",
        },
        // {
        //   id: "radiology",
        //   title: "Ct Abdomen",
        //   type: "item",
        //   url: "/clinical-modules/radiology/ct-abdomen",
        // },
        // {
        //   id: "radiology",
        //   title: "Ct Acute Infarct(Non Contrast)",
        //   type: "item",
        //   url: "/clinical-modules/radiology/ct-acute-infarct",
        // },
        {
          id: "radiology",
          title: "Barium Enema",
          type: "item",
          url: "/clinical-modules/radiology/barium-enema",
        },
        {
          id: "radiology",
          title: "Barium Meal",
          type: "item",
          url: "/clinical-modules/radiology/barium-meal",
        },
        {
          id: "radiology",
          title: "Barium Swallow",
          type: "item",
          url: "/clinical-modules/radiology/barium-swallow",
        },
        {
          id: "radiology",
          title: "Us Obstetrics Twin",
          type: "item",
          url: "/clinical-modules/radiology/ut-obstetrics-report-twin",
        },
        {
          id: "radiology",
          title: "Us Abdomen and Pelvis",
          type: "item",
          url: "/clinical-modules/radiology/ut-abdomen-and-pelvis",
        },
        {
          id: "radiology",
          title: "Doppler Us Lt Lower Limb Veins",
          type: "item",
          url: "/clinical-modules/radiology/doppler-ut-lt-lower-limb-veins",
        },
        {
          id: "radiology",
          title: "Doppler US Both Lower Limb Veins",
          type: "item",
          url: "/clinical-modules/radiology/doppler-ut-both-lower-limb-veins",
        },
        {
          id: "radiology",
          title: "Doppler US Both Lower Limb Veins",
          type: "item",
          url: "/clinical-modules/radiology/doppler-ut-both-lower-limb-veins",
        },
        {
          id: "radiology",
          title: "Doppler Us Both Lower Limb Arteries",
          type: "item",
          url: "/clinical-modules/radiology/doppler-ut-both-lower-limb-arteries",
        },
        {
          id: "radiology",
          title: "Doppler Us Lt Upper Limb Arteries",
          type: "item",
          url: "/clinical-modules/radiology/doppler-ut-lt-upper-limb-arteries",
        },
        {
          id: "radiology",
          title: "Doppler Us Lt Upper Limb Veins",
          type: "item",
          url: "/clinical-modules/radiology/doppler-ut-lt-upper-limb-veins",
        },
        {
          id: "radiology",
          title: "Doppler Us Both Upper Limb Veins",
          type: "item",
          url: "/clinical-modules/radiology/doppler-ut-both-upper-limb-veins",
        },
        {
          id: "radiology",
          title: "Doppler Us Rt Upper Limb Veins",
          type: "item",
          url: "/clinical-modules/radiology/doppler-ut-rt-upper-limb-veins",
        },
        {
          id: "radiology",
          title: "Us Obstetrics Late",
          type: "item",
          url: "/clinical-modules/radiology/ut-obstetrics-report-late",
        },
        {
          id: "radiology",
          title: "US Obstetrics Anomaly",
          type: "item",
          url: "/clinical-modules/radiology/ut-obstetrics-report-anomaly",
        },
        {
          id: "radiology",
          title: "Us Transabdominal & Transvaginal",
          type: "item",
          url: "/clinical-modules/radiology/ut-transabdominal-transvaginal",
        },
        {
          id: "radiology",
          title: "Us Pelvis (Male)",
          type: "item",
          url: "/clinical-modules/radiology/ut-pelvis-male",
        },
        {
          id: "radiology",
          title: "Us Pelvis (Female)",
          type: "item",
          url: "/clinical-modules/radiology/ut-pelvis-female",
        },
        {
          id: "radiology",
          title: "US Neck Report",
          type: "item",
          url: "/clinical-modules/radiology/ut-neck-report",
        },
        {
          id: "radiology",
          title: "Us Chest Report",
          type: "item",
          url: "/clinical-modules/radiology/ut-chest-report",
        },
        {
          id: "radiology",
          title: "Us Abdomen Report",
          type: "item",
          url: "/clinical-modules/radiology/ut-abdomen-report",
        },
        {
          id: "radiology",
          title: "Us Abdomen and Pelvis Female",
          type: "item",
          url: "/clinical-modules/radiology/ut-abdomen-pelvis-female",
        },
        {
          id: "radiology",
          title: "Us Pelvis Transabdominal",
          type: "item",
          url: "/clinical-modules/radiology/ut-pelvis-transabdominal",
        },
        {
          id: "radiology",
          title: "Echocardiographic-Report",
          type: "item",
          url: "/clinical-modules/radiology/echocardiographic-report",
        },

        {
          id: "radiology",
          title: "Echocardiography Report (*New)",
          type: "item",
          url: "/clinical-modules/radiology/ecg-report",
        },
        {
          id: "radiology",
          title: "Ecg Template (*New)",
          type: "item",
          url: "/clinical-modules/radiology/ecg-template",
        },

        {
          id: "radiology",
          title: "Ct-scan-report",
          type: "item",
          url: "/clinical-modules/radiology/ct-scan-report",
        },
        {
          id: "radiology",
          title: "Doppler Us Lt Lower Limb Arteries",
          type: "item",
          url: "/clinical-modules/radiology/doppler-ut-lt-lower-arteries",
        },
        {
          id: "radiology",
          title: "Doppler Us Rt Lower Limb Arteries",
          type: "item",
          url: "/clinical-modules/radiology/doppler-ut-rt-lower-limb-arteries",
        },
        {
          id: "radiology",
          title: "Doppler Us Rt Lower Limb Veins",
          type: "item",
          url: "/clinical-modules/radiology/doppler-ut-rt-lower-limb-veins",
        },
        {
          id: "radiology",
          title: "Us-Lt-Thigh-Mass",
          type: "item",
          url: "/clinical-modules/radiology/us-lt-thigh-mass-report",
        },
        {
          id: "radiology",
          title: "Us-Lt-Scrotum-Report",
          type: "item",
          url: "/clinical-modules/radiology/us-lt-scrotum-report",
        },
        {
          id: "radiology",
          title: "Us-Penile-Report",
          type: "item",
          url: "/clinical-modules/radiology/us-penile-report",
        },
        {
          id: "radiology",
          title: "Us-Right-Inguinal-Mass",
          type: "item",
          url: "/clinical-modules/radiology/us-right-inguinal-mass",
        },
        {
          id: "radiology",
          title: "Us-Breast",
          type: "item",
          url: "/clinical-modules/radiology/us-breast",
        },
        {
          id: "radiology",
          title: "Us-Gluteal",
          type: "item",
          url: "/clinical-modules/radiology/us-gluteal",
        },
        {
          id: "radiology",
          title: "Us-Left-Thigh-Mass-Report",
          type: "item",
          url: "/clinical-modules/radiology/us-left-thigh-mass-report",
        },
        {
          id: "radiology",
          title: "Us-Posterior-Chest-Wall",
          type: "item",
          url: "/clinical-modules/radiology/us-posterior-chest-wall",
        },
        {
          id: "radiology",
          title: "Us-Anterior-Chest-Wall-Report",
          type: "item",
          url: "/clinical-modules/radiology/us-anterior-chest-wall-report",
        },
        {
          id: "radiology",
          title: "Us-Neonatal-Head-Report",
          type: "item",
          url: "/clinical-modules/radiology/us-neonatal-head-report",
        },
        {
          id: "radiology",
          title: "Neck & Doppler Report",
          type: "item",
          url: "/clinical-modules/radiology/us-neck-and-doppler-report",
        },
        {
          id: "radiology",
          title: "Us Supraclavicular Report",
          type: "item",
          url: "/clinical-modules/radiology/us-supraclavicular-report",
        },
        {
          id: "radiology",
          title: "General Template",
          type: "item",
          url: "/clinical-modules/radiology/general-template",
        },
        {
          id: "radiology",
          title: "US Tranrectal Prostate Report",
          type: "item",
          url: "/clinical-modules/radiology/ut-tranrectal-prostate",
        },
        {
          id: "radiology",
          title: "PR Both Knee Joints Report",
          type: "item",
          url: "/clinical-modules/radiology/pr-both-knee-joints",
        },
        {
          id: "radiology",
          title: "PR Chest Report",
          type: "item",
          url: "/clinical-modules/radiology/pr-chest-report",
        },
        {
          id: "radiology",
          title: "PR Left Ankle Joint Report",
          type: "item",
          url: "/clinical-modules/radiology/pr-lt-ankle-joint",
        },
        {
          id: "radiology",
          title: "PR Left Knee Joint Report",
          type: "item",
          url: "/clinical-modules/radiology/pr-lt-knee-joint",
        },
        {
          id: "radiology",
          title: "PR Left Wrist Report",
          type: "item",
          url: "/clinical-modules/radiology/pr-lt-wrist",
        },
        {
          id: "radiology",
          title: "PR Pelvis Report",
          type: "item",
          url: "/clinical-modules/radiology/pr-pelvis-report",
        },
        {
          id: "radiology",
          title: "PR Right Shoulder Joint Report",
          type: "item",
          url: "/clinical-modules/radiology/pr-rt-shoulder-joint",
        },
        {
          id: "radiology",
          title: "PR Soft Tissue Neck",
          type: "item",
          url: "/clinical-modules/radiology/pr-soft-tissue-neck",
        },
        {
          id: "radiology",
          title: "Mammogram Right Breast",
          type: "item",
          url: "/clinical-modules/radiology/mammogram-right-breast",
        },
        {
          id: "radiology",
          title: "Mammogram Left Breast",
          type: "item",
          url: "/clinical-modules/radiology/mammogram-left-breast",
        },
        {
          id: "radiology",
          title: "Mammogram Both Breast",
          type: "item",
          url: "/clinical-modules/radiology/mammogram-both-breast",
        },
        {
          id: "radiology",
          title: "Hsg",
          type: "item",
          url: "/clinical-modules/radiology/hsg",
        },
        {
          id: "radiology",
          title: "CT Bone and Age Assessment",
          type: "item",
          url: "/clinical-modules/radiology/ct-bone-age",
        },
        // {
        //   id: "radiology",
        //   title: "Hsg Bilateral Tubal Blockage",
        //   type: "item",
        //   url: "/clinical-modules/radiology/hsg-bilateral-tubal-blockage",
        // },
        // {
        //   id: "radiology",
        //   title: "Hsg Unilateral Tubal Blockage",
        //   type: "item",
        //   url: "/clinical-modules/radiology/hsg-unilateral-blockage",
        // },
        // {
        //   id: "radiology",
        //   title: "Hsg Hydosalpinx",
        //   type: "item",
        //   url: "/clinical-modules/radiology/hsg-hydosalpinx",
        // },
      ],
    };

    const CLINICAL_NAV_ITEMS = {
      id: "clinical-laboratories",
      title: "Clinical Lab",
      type: "collapse",
      url: "/clinical-modules/clinical-laboratories",
      icon: "feather icon-copy",
      children: [
        {
          id: "clinical-laboratories",
          title: "Home",
          type: "item",
          url: "/clinical-modules/clinical-laboratories",
        },
        {
          id: "lower-gi-endoscopy-report",
          title: "Lower GI Endoscopy Report",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/lower-gi-endoscopy-report",
        },
        {
          id: "electrocardiogram-report",
          title: "Electrocardiogram Report",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/electrocardiogram-report",
        },
        {
          id: "electrolytes",
          title: "Electrolytes",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/electrolytes",
        },
        {
          id: "coagulation",
          title: "Coagulation",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/coagulation",
        },
        {
          id: "lipid-profile-test",
          title: "Lipid Profile Test",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/lipid-profile-test",
        },
        {
          id: "parasitology",
          title: "Parasitology",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/parasitology",
        },
        {
          id: "urinalysis",
          title: "Urinalysis",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/urinalysis",
        },
        {
          id: "upper-gi-endoscopy-report",
          title: "Upper GI Endoscopy Report",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/upper-gi-endoscopy-report",
        },
        {
          id: "chemical-pathology-template",
          title: "Chemical Pathology",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/general-lab-template",
        },
        {
          id: "general-dna-templates",
          title: "General DNA Templates",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/general-dna-templates",
        },
        {
          id: "haematology-report",
          title: "Haematology Report",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/haematology-report",
        },
        {
          id: "custom-lab-template",
          title: "Custom Lab Template",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/custom-lab-template",
        },
        {
          id: "hormones-analysis",
          title: "Hormones Analysis",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/hormones-analysis",
        },
        {
          id: "serology-template",
          title: "Serology Template",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/serology-template",
        },
        {
          id: "microbiology",
          title: "Microbiology",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/microbiology",
        },
        {
          id: "combined-hematology-template",
          title: "Combined Haematology Template",
          type: "item",
          url: "/clinical-modules/clinical-laboratories/combined-hematology-template",
        },
        // {
        //   id: "microbiology",
        //   title: "Microbiology",
        //   type: "item",
        //   url: "/clinical-modules/clinical-laboratories/microbiology",
        // },

      ]

      };


    // const DASHBOARD_NAV_ITEM = {
    //   id: "dashboard",
    //   title: "Dashboard",
    //   type: "item",
    //   url: "/dashboard/staff",
    //   icon: "feather icon-activity",
    // };

    // const ADMIN_DASHBOARD_NAV_ITEM = {
    //   id: "admin-dashboard",
    //   title: "Admin Dashboard",
    //   type: "item",
    //   url: "/dashboard/admin",
    //   icon: "feather icon-activity",
    // };

    const SUBSCRIPTION_NAV_ITEM = {
      id: "subscription",
      title: "Subscription",
      type: "item",
      url: "/subscription/plans",
      icon: "feather icon-shopping-cart",
    };
    const KEYMETRICS_NAV_ITEM = {
      id: "keymetrics",
      title: "Key Metrics",
      type: "item",
      url: "/key-metrics",
      icon: "feather icon-bar-chart-2",
    };

    const NavigationItems = [
      {
        id: "other",
        title: "Menu",
        type: "group",
        icon: "feather icon-align-left",
        children: [

          // sessionStorage.getItem("role") != "Staff"
          //   ? ADMIN_DASHBOARD_NAV_ITEM
          //   : {},
          // sessionStorage.getItem("role") == "Staff" ||
          // sessionStorage.getItem("role") == "Owner"
          //   ? DASHBOARD_NAV_ITEM
          //   : {},
          // {
          //   id: "admin-dashboard",
          //   title: "Admin Dashboard",
          //   type: "item",
          //   url: "/dashboard/admin",
          //   icon: "feather icon-activity",
          // },
          // {
          //   id: "dashboard",
          //   title: "Staff Dashboard",
          //   type: "item",
          //   url: "/dashboard/staff",
          //   icon: "feather icon-activity",
          // },
          {
            id: "patient",
            title: "Patients",
            type: "item",
            url: "/patient",
            icon: "feather icon-users",
          },
          {
            id: "patient",
            title: "Patients",
            type: "collapse",
            hidden: true,
            url: "/patient",
            children: [
              {
                id: "patient",
                title: "Trash",
                type: "item",
                url: "/patient/archive",
              },
            ],
          },

          {
            id: "clinical-modules",
            title: "Clinical Modules",
            type: "collapse",
            icon: "feather icon-folder",

            // badge: {
            //   title: "Update",
            //   type: "badge-danger"
            // },
            children: [
              activateGP || activePathRD ? GENERAL_PRACTISE_NAV_ITEMS : {},
              activatePath || activePathRD ? PATHOLOGY_NAV_ITEMS : {},
              activePathRD || activePathRD ? RADIOLOGY_NAVS : {},
              activePathRD || activePathRD ? CLINICAL_NAV_ITEMS : {},
            ],
          },
          {
            id: "calendar",
            title: "Calendar",
            type: "item",
            url: "/calendar",
            icon: "feather icon-calendar",
            // url: "javascript:",
            // external: true
          },
          {
            id: "billing",
            title: "Billing",
            type: "collapse",
            url: "/billing",
            icon: "feather icon-credit-card",

            // url: "javascript:",
            // external: true
            children: [
              {
                id: "item-services",
                title: "Item / Services",
                type: "item",
                url: "/billing/item-services",
                icon: "feather icon-list",
              },
              {
                id: "bills",
                title: "Bills",
                type: "item",
                url: "/billing/bills",
                icon: "feather icon-bold",
              },
              {
                id: "transactions",
                title: "Transactions",
                type: "item",
                url: "/billing/transactions",
                icon: "feather icon-file-text",
              },
              // {
              //   id: "debtors",
              //   title: "Debtors",
              //   type: "item",
              //   url: "/billing/debtors",
              //   // badge: {
              //   //   title: "New",
              //   //   type: "badge-danger"
              //   // },
              //   icon: "feather icon-file-minus",
              // },
              {
                id: "debtors-report",
                title: "Debtors Report",
                type: "item",
                url: "/billing/debtors-report",
                icon: "feather icon-file-minus",
              },
            ],
          },
          {
            id: "my-profile",
            title: "My Profile",
            type: "item",
            url: "/profile",
            // badge: {
            //   title: "Updated",
            //   type: "badge-danger"
            // },
            icon: "feather icon-user",
          },
          {
            id: "request",
            title: "Requests",
            type: "item",
            url: "/request",
            // badge: {
            //   title: "Updated",
            //   type: "badge-danger"
            // },
            // count: 6,
            icon: "feather icon-copy",
          },

          // Check the role and show the subscription Nav Item

          sessionStorage.getItem("role") == "Admin" ||
          sessionStorage.getItem("role") == "Partner" ||
          sessionStorage.getItem("role") == "Owner"||
          sessionStorage.getItem("role") == "Doctor"
            ? SUBSCRIPTION_NAV_ITEM
            : {},

            {
              id: "subscription",
              title: "Subscription",
              type: "collapse",
              url: "/subscription/plans",
              hidden: true,
              // external: true
              children: [
                {
                  id: "plans",
                  title: "Plans",
                  type: "item",
                  url: "/subscription/plans",

                  // icon: "feather icon-list",
                },
                {
                  id: "stripe-subscription",
                  title: "Manage Subscription",
                  type: "item",
                  url: "/stripe-subscription",
                  // icon: "feather icon-list",
                },
              ],
            },
          ],
        },
        {
          id: "other",
          title: "Administration",
          type: "group",
          icon: "feather icon-align-left",
          children: [

            {
              id: "key-metrics",
              title: "Key Metrics",
              type: "item",
              url: "/key-metrics",
              icon: "feather icon-bar-chart-2",
            },
            //here is mine brian
            {
              id: "clients",
              title: "Clients",
              type: "collapse",
              url: "/clients",
              icon: "feather icon-user-plus",
              children: [
                {
                  id: "requesting-doctors",
                  title: "Requesting Doctors",
                  type: "item",
                  url: "/clients/requesting-doctors",
                },
                {
                  id: "requesting-facilities",
                  title: "Requesting Facility",
                  type: "item",
                  url: "/clients/requesting-facilities",
                },
              ],
            },
            {
              id: "sms-settings",
              title: "SMS Settings",
              type: "item",
              url: "/sms-settings",
              // badge: {
              //   title: "Update",
              //   icon: "feather icon-ok",
              //   type: "badge-danger"
              // },
              icon: "feather icon-message-circle",
            },

            {
              id: "facility-profile",
              title: "Facility Profile",
              type: "item",
              url: "/facility-profile",
              // badge: {
              //   title: "Updated",
              //   type: "badge-danger",
              // },
              icon: "feather icon-grid",
              // url: "javascript:",
              // external: true
            },
            // {
            //   id: "user-management",
            //   title: "User Management",
            //   type: "item",
            //   url: "/user-management",
            //   icon: "feather icon-user-check",
            //   // url: "javascript:",
            //   // external: true
            // },
            {
              id: "user-management",
              title: "User Management",
              type: "item",
              url: "/user-management",
              icon: "feather icon-user-check",
            },
            {
              id: "user-management",
              title: "User Management",
              type: "collapse",
              hidden: true,
              url: "/user-management",
              children: [
                {
                  id: "user-management",
                  title: "Blocked Users",
                  type: "item",
                  url: "/user-management/blocked-users",
                },
              ],
            },
            // {
            //   id: "facility-profile",
            //   title: "Facility Profile",
            //   type: "item",
            //   url: "/settings",
            //   icon: "feather icon-settings",
            // },
          ],
        },
        {
          id: "contact-support",
          title: "Contact Support",
          type: "item",
          url: "/contact-support",
          hidden: true,
        },
      ];

      return NavigationItems;
    }
  }
