<div *ngIf="permissions.clients.view " class="row mb-4 align-items-center" style="margin-top: -130px">
  <div class="col-lg-6">
    <h3 class="">Cases</h3>
  </div>
</div>


<div class="card" *ngIf="permissions.clients.view ">
  <div class="card-header py-3">
    <div class="row">
      <div class="col">
        <h4>Interval</h4>
      </div>
      <!-- <div [ngClass]="searchVal ? 'd-flex-inline' : 'd-none'" class="col text-right">
        <button (click)="clear()" class="btn btn-danger btn-sm"
                type="button">
          Clear
        </button>
      </div> -->
      <div class="col text-right">
        <p class="mb-0 small">Current Data</p>
        <h5 class="mr-0" *ngIf="!searchVal || (searchVal && (!newStartDate || !newEndDate))">All Time</h5>
        <h5 class="mr-0" *ngIf="searchVal && newStartDate && newEndDate" class="mt-0">From: {{newStartDate}} - To: {{newEndDate}}</h5>
      </div>
      <!-- <div class="col text-right" *ngIf="newStartDate && newEndDate else noData">
        <span *ngIf="newStartDate">From: </span>{{newStartDate}} <span *ngIf="newEndDate">- To:</span> {{newEndDate}}
      </div>
      <ng-template #noData>
        2024
      </ng-template> -->
    </div>
  </div>

  <div class="card-body">
    <div class="row">
    <div class="col-md-6 col-sm-12">
      <input placeholder="Click to enter date" required type="text"
      class="form-control" type="date" [(ngModel)]="newStartDate" />
      <label>From:</label>
    </div>
    <div class="col-md-6 col-sm-12">
      <input placeholder="Click to enter date" required type="text"
      class="form-control" type="date" [(ngModel)]="newEndDate" />
      <label>To:</label>
    </div>
    </div>
    <div class="col-md-12 col-sm-12 d-flex justify-content-center">
      <button (click)="getInputDate()"
      [disabled]="false"
      class="btn btn-success"
      style="padding:10px 15%;"
      type="submit">

      <span class="spinner-border-sm" role="status"></span>
      Search
    </button>
  </div>
  <div class="col text-right" *ngIf="searchVal">
    <button (click)="clear()" class="btn btn-danger btn-sm" type="button">
      Clear
    </button>
  </div>
  <!-- <div [ngClass]="searchCal ? 'd-flex-inline' : 'd-none'" class="col text-right">
    <button (click)="clear()" class="btn btn-danger btn-sm">
      Clear
    </button>
  </div> -->
</div>
  <!-- <div class="card-body">
    <form (submit)="onIntervalSubmit($event)" action="">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="from" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="from">From:</label>
        </div>
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="to" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="to">To:</label>
        </div>
        <div class="col-md-12 col-sm-12 d-flex justify-content-center">
          <button [disabled]="false"
                  class="btn btn-success"
                  style="padding:10px 15%;"
                  type="submit">

            <span *ngIf="this.loading" class="spinner-border spinner-border-sm" role="status"></span>
            Search<span *ngIf="this.loading" class="load-text">ing...</span>
          </button>
        </div>
      </div>
    </form>
  </div> -->
</div>

<div class="row" *ngIf="permissions.clients.view || permissions.keyMetrics.view">
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Total Doctor</h5>
        <hr>
        <h5>{{getAllDoctors.length}}</h5>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-body text-center">
        <h5 class="">Total Cases Finalized</h5>
        <hr>
        <!-- <h5>{{totalForms}}</h5> -->
        <h5>{{getCases.length}}</h5>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="permissions.clients.view || permissions.keyMetrics.view">
  <div class="card-header">
    <div class="row">
      <h4 class="col-sm-10">Details</h4>
      <button class="col-sm-2" style="margin-left: 2%;" (click)="getAllDoctorData(); allDoctorModalView.show();" class="btn btn-round btn-warning font-weight-bold mr-2" type="button">View & Export All</button>
    </div>
  </div>
  <div class="card-body">
    <table datatable [dtOptions]="dtOptions" class="table table-bordered table-striped" id="report-table">
      <thead>
      <tr>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Doctor</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Cases Finalized</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Billed</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Paid</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Arrears</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let fac of getAllDoctors">
        <th> {{fac.full_name}}</th>
        <td>{{fac.total_forms}}</td>
        <td>{{ currencyBase }} {{fac.total_billed | number: "1.2"}}</td>
        <td>{{ currencyBase }} {{fac.total_paid | number: "1.2"}}</td>
        <td>{{ currencyBase }} {{fac.total_areas | number: "1.2"}}</td>
        <td>
          <button (click)="getCasesView(fac?.id)" [placement]="'top'" class="btn btn-success btn-sm" data-backdrop="static"
                  id="viewBtn" ngbTooltip="VIEW">
            <i class="feather icon-eye"></i> View
          </button>
          <!-- <button (click)="view(fac)" [placement]="'top'" class="btn btn-success btn-sm" data-backdrop="static"
                  id="viewBtn" ngbTooltip="VIEW">
            <i class="feather icon-eye"></i> View
          </button> -->
        </td>

      </tr>
      </tbody>
    </table>
  </div>
  <!-- <div class="card-footer">
    <pagination-controls
      (pageBoundsCorrection)="p = $event"
      (pageChange)="p = $event"
      autoHide="false"
      directionLinks="true"
      maxSize="9"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: right"
    >
    </pagination-controls>
  </div> -->
</div> 

<!-- -------------------------------------------------------------------------------- VIEW --------------------------------------------------------------------------------------- -->
<app-ui-modal #modalView [containerClick]="false" [dialogClass]="'modal-xl btn-page'" class="doNotPrint">
  <div class="app-modal-header">
    <h4 class="modal-title" style="color:#0873ee;text-transform:uppercase">Doctor Report Details</h4>
  </div>
  <div class="app-modal-header">
    <button (click)="printElement(); modalView.hide();" class="btn btn-secondary" type="button">Print</button>&nbsp;
    <button (click)="csvMe(); modalView.hide();" class="btn btn-secondary" type="button">Export</button>&nbsp;
    <button (click)="modalView.hide()" class="btn btn-danger" data-dismiss="modal" type="button">Close</button>
  </div>
  <div class="app-modal-body main-look">
    <div id="contentToPrint">
      <div class="row mb-4">
        <div class="col-sm-12">
          <img *ngIf="companyData?.company_logo !== null" alt=""
               src="{{ companyData?.company_logo }}" style="width: 100%; height: 160px;">
        </div>
      </div>

      <div class="">
        <h3 class="mb-0">Doctor Report Details</h3>
        <h4 class="" >{{doctorName2}} {{speciality}}</h4>
        <p  *ngIf="searchVal && newStartDate && newEndDate" class="mt-0">From: {{newStartDate}} - To: {{newEndDate}}</p>
        <hr>
      </div>
      <table class="table table-responsive table-bordered table-striped doNotPrint" id="report-table">
        <thead>
        <tr>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Specimen</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Case ID</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Patient</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Billed</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Paid</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Arrears</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Date Finalized</th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let rep of doctorFormsDetails | paginate: { itemsPerPage: 8, currentPage: detailPage, totalItems: doctorFormsDetails.length, id: 'details' };">
          <td><span>{{ rep?.form_name ? rep.form_name : 'N/A' }}</span></td>
          <td><span>{{rep.case_id ? rep.case_id : 'N/A' }}</span></td>
          <td><span>{{rep.patient_name ? rep.patient_name : 'N/A' }}</span></td>
          <td><span>₵{{rep.total_amount  ? rep.total_amount : 0 | number: "1.2"}}</span></td>
          <td><span>₵{{rep.amount_paid ? rep.amount_paid : 0 | number: "1.2"}}</span></td>
          <td><span>₵{{rep.arreas  ? rep.arreas : 0 | number: "1.2"}}</span></td>
          <td><span
            style="font-size:inherit;color:#0a661f;font-weight:bold;">{{ rep.updated_at ? rep.updated_at : 'N/A'  | date: "MMM d, y":"GMT"}}</span>
          </td>
        </tr>
        </tbody>
      </table>

      <table class="table table-bordered table-striped toPrint">
        <thead>
        <tr>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Specimen</th>
          <th style="padding-left: 10px; padding-right: 10px" width="20%">Case ID</th>
          <th style="padding-left: 10px; padding-right: 10px" width="20%">Patient</th>
          <th style="padding-left: 10px; padding-right: 10px" width="5%">Billed</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Paid</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Arrears</th>
          <th style="padding-left: 10px; padding-right: 10px" width="15%">Date Created</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let rep of doctorFormsDetails">
          <td><span>{{ rep?.form_name ? rep.form_name : 'N/A' }}</span></td>
          <td><span>{{rep.case_id ? rep.case_id : 'N/A' }}</span></td>
          <td><span>{{rep.patient_name ? rep.patient_name : 'N/A' }}</span></td>
          <td><span>₵{{rep.total_amount  ? rep.total_amount : 0 | number: "1.2"}}</span></td>
          <td><span>₵{{rep.amount_paid ? rep.amount_paid : 0 | number: "1.2"}}</span></td>
          <td><span>₵{{rep.arreas  ? rep.arreas : 0 | number: "1.2"}}</span></td>
          <td><span
            style="font-size:inherit;color:#0a661f;font-weight:bold;">{{ rep.created_at ? rep.created_at : 'N/A'  | date: "MMM d, y":"GMT"}}</span>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="app-modal-footer">
    <pagination-controls
      (pageBoundsCorrection)="detailPage = $event"
      (pageChange)="detailPage = $event"
      autoHide="false"
      directionLinks="true"
      id="details"
      maxSize="9"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: left"
    >
    </pagination-controls>
    <button (click)="modalView.hide();" class="btn btn-danger" type="button">Close</button>
  </div>
</app-ui-modal>
<!-- ------------------------------------------------------------------------------ END VIEW ------------------------------------------------------------------------------------- -->


<!-- ------------------------------------------------------------------------------ ALL DOCTOR VIEW ------------------------------------------------------------------------------------- -->

<app-ui-modal #allDoctorModalView [containerClick]="false" [dialogClass]="'modal-xl btn-page'" class="doNotPrint">
  <div class="app-modal-header">
    <h4 class="modal-title" style="color:#0873ee;text-transform:uppercase">All Doctors Report Details</h4>
  </div>
  <div class="app-modal-header">
    <!-- <button (click)="printElement(); allDoctorModalView.hide();" class="btn btn-secondary" type="button">Print</button>&nbsp; -->
    <button (click)="csvAllData(); allDoctorModalView.hide();" class="btn btn-secondary" type="button">Export</button>&nbsp;
    <button (click)="allDoctorModalView.hide()" class="btn btn-danger" data-dismiss="modal" type="button">Close</button>
  </div>
  <div class="app-modal-body main-look">
    <div id="contentToPrint">
      <div class="row mb-4">
        <div class="col-sm-12">
          <img *ngIf="companyData?.company_logo !== null" alt=""
               src="{{ companyData?.company_logo }}" style="width: 100%; height: 160px;">
        </div>
      </div>

      <div class="">
        <h3 class="mb-0">All Doctors Report Details</h3>
        <!-- <h4 class="" >{{doctorName2}} {{speciality}}</h4>
        <p  *ngIf="searchVal && newStartDate && newEndDate" class="mt-0">From: {{newStartDate}} - To: {{newEndDate}}</p> -->
        <hr>
      </div>
      <table class="table table-responsive table-bordered table-striped doNotPrint" id="report-table">
        <thead>
        <tr>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Doctor</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Specimen</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Case ID</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Patient</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Billed</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Paid</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Arrears</th>
          <th style="padding-left: 10px; padding-right: 10px" width="12.5%">Date Finalized</th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let rep of allDoctorData | paginate: { itemsPerPage: 8, currentPage: allDoctorDetailPage, totalItems: allDoctorData.length, id: 'details' };">
          <td><span>{{ rep?.doctor ? rep.doctor : 'N/A' }}</span></td>
          <td><span>{{ rep?.form_name ? rep.form_name : 'N/A' }}</span></td>
          <td><span>{{rep.case_id ? rep.case_id : 'N/A' }}</span></td>
          <td><span>{{rep.patient_name ? rep.patient_name : 'N/A' }}</span></td>
          <td><span>₵{{rep.total_amount  ? rep.total_amount : 0 | number: "1.2"}}</span></td>
          <td><span>₵{{rep.amount_paid ? rep.amount_paid : 0 | number: "1.2"}}</span></td>
          <td><span>₵{{rep.arreas  ? rep.arreas : 0 | number: "1.2"}}</span></td>
          <td><span
            style="font-size:inherit;color:#0a661f;font-weight:bold;">{{ rep.updated_at ? rep.updated_at : 'N/A'  | date: "MMM d, y":"GMT"}}</span>
          </td>
        </tr>
        </tbody>
      </table>
      </div>
      </div>
      <div class="app-modal-footer">
        <pagination-controls
          (pageBoundsCorrection)="allDoctorDetailPage = $event"
          (pageChange)="allDoctorDetailPage = $event"
          autoHide="false"
          directionLinks="true"
          id="details"
          maxSize="9"
          nextLabel="Next"
          previousLabel="Previous"
          responsive="true"
          screenReaderCurrentLabel="You're on page"
          screenReaderPageLabel="page"
          screenReaderPaginationLabel="Pagination"
          style="float: left"
        >
        </pagination-controls>
        <button (click)="allDoctorModalView.hide();" class="btn btn-danger" type="button">Close</button>
      </div>
      </app-ui-modal>
    


<!-- ------------------------------------------------------------------------------ ALL DOCTOR VIEW END------------------------------------------------------------------------------------- -->