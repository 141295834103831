import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "../../../others/services/authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import { GeneralService } from "../../../services/general.service";
import "jquery";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { AlertService } from "../../../others/services/alert.service";
import { FormGroup, FormBuilder, FormControl, FormsModule, Validators, NgForm } from "@angular/forms";
import { isAfter, isBefore, isEqual } from "date-fns";
import { ActivatedRoute, Router } from "@angular/router";
//import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';


declare let $: any;

@Component({
  selector: "app-expenses",
  templateUrl: "./expenses.component.html",
  styleUrls: ["./expenses.component.scss"],
})
export class ExpensesComponent implements OnInit {
  @ViewChild("addExpenseModal") addExpenseModal;
  @ViewChild("addCategoryModal") addCategoryModal;

  searchVal = false;
  year = new Date().getFullYear();
  loading = false;

  user_array = (str) => {
    return str.trim().split(",");
   };

  singleCurrency = this.user_array(sessionStorage.getItem("currency"));
  currencyNme = this.singleCurrency[0];
  currencySym = this.singleCurrency[1];
  currencyBase = this.singleCurrency[2];

  categoryList = ["All Categories"];
  allCategory = [];
  expenseList = [];
  yearExpenseList = [];
  /* Holds the list of searched expense list based on a category or all */
  searchExpenses = [];
  searchCategory = [];
  /* Holds the list of the filtered expense list using date from and date to */
  searchedExpressList = [];
  selectedCategory = "All Categories";

  totalAmount = 0;

  p = 0;
  searchText = "";
  actionButtons = true;
  permissions: any = [];
  viewExpenses: any;
  id: any;
  editExpenses: any;
  expenseName: any;

  expenseForm: FormGroup;
  categoryForm: FormGroup;
  expenseEditForm: FormGroup;
  categoryEditForm: FormGroup;
  editCategory: any;

  expenseDetails = {
    created_by: "",
    edited_by: "",
    expense: "",
    category: "",
    amount: "",
  };

  categoryDetails = {
    category_name: "",
  };

  expenseEditDetails = {
    edited_by: "",
    expense: "",
    category: "",
    amount: "",
  };

  categoryEditDetails = {
    category_name: "",
  };
  comData: any;

  constructor(
    public auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private generalService: GeneralService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService
  ) {

  }

  ngOnInit() {
    this.getCategories();
    this.getExpenses();
    this.getPermissions();
    this.getComProfile();

    this.expenseForm = this.formBuilder.group({
      expense: [null],
      category: [null],
      amount: [null],
    });

    this.categoryForm = this.formBuilder.group({
      category_name: [null],
    });

    this.expenseEditForm = this.formBuilder.group({
      expense: [null],
      category: [null],
      amount: [null],
    });
    this.categoryEditForm = this.formBuilder.group({
      category_name: [null],
    });

  }

  prepareCreateForm() {
    this.expenseForm.get("expense").setValue(this.editExpenses.expense);
    this.expenseForm.get("category").setValue(this.editExpenses.category);
    this.expenseForm.get("amount").setValue(this.editExpenses.amount);
  }

  prepareCatCreateForm() {
    this.categoryForm.get("category_name").setValue(this.editCategory.category_name);
  }

  prepareEditForm() {
    this.expenseEditForm.get("expense").setValue(this.editExpenses.expense);
    this.expenseEditForm.get("category").setValue(this.editExpenses.category);
    this.expenseEditForm.get("amount").setValue(this.editExpenses.amount);
  }

  prepareCatEditForm() {
    this.categoryEditForm.get("category_name").setValue(this.editCategory.category_name);
  }

  setCatData() {
    this.categoryDetails.category_name = this.categoryForm.controls[
      "category_name"
    ].value;
  }
  setData() {
    this.expenseDetails.created_by = sessionStorage.getItem("userID");
    this.expenseDetails.edited_by = sessionStorage.getItem("userID");
    this.expenseDetails.expense = this.expenseForm.controls[
      "expense"
    ].value;
    this.expenseDetails.category = this.expenseForm.controls[
      "category"
    ].value;
    this.expenseDetails.amount = this.expenseForm.controls[
      "amount"
    ].value;
  }

  setEditData() {
    this.expenseEditDetails.edited_by = sessionStorage.getItem("userID");
    this.expenseEditDetails.expense = this.expenseEditForm.controls[
      "expense"
    ].value;
    this.expenseEditDetails.category = this.expenseEditForm.controls[
      "category"
    ].value;
    this.expenseEditDetails.amount = this.expenseEditForm.controls[
      "amount"
    ].value;
  }
  setCatEditData() {
    this.categoryEditDetails.category_name = this.categoryEditForm.controls[
      "category_name"
    ].value;
  }
  // Get user permissions
//  getPermissions() {
//   this.permissions = {
//     expenses: {
//       view: false
//     },
//   };

//   this.auth
//     .get("user_permissions/" + sessionStorage.getItem("userID")).toPromise()
//     .then(
//       (response: any) => {
//         this.permissions = response.permissions.permissions[0];
//         this.actionButtons = Object.keys(this.permissions.expenses).every((key) => {
//           return key == "create" ? false : this.permissions.expenses[key];
//         })
//       }
//     );
// }

getPermissions() {
  this.auth.get('user_permissions/' + sessionStorage.getItem('userID')).subscribe(
    (response: any) => {
      this.permissions = response.permissions.permissions[0];
    },
    (error: any) => {

    }
  );
}
  /**
   * The function gets all the categories from the database and stores them in the categoryList array
   */
  getCategories(): void {
    this.auth.get("categories").subscribe((response: any) => {
      this.categoryList = [
        { category_name: "All Categories" },
        ...response.data,
      ];
      this.allCategory = response.data;
      // this.getAllCategoryData();
    });
  }

  /**
   * The function gets the expenses from the database and assigns them to the expenseList variable
   */
  getExpenses(): void {
    this.spinner.show();
    this.auth.get("expenses").subscribe((response: any) => {
      this.expenseList = response.data;
      this.searchExpenses = this.yearExpenseList = response.data.filter(
        (ele) =>
          new Date(ele.created_at).getFullYear() === new Date().getFullYear()
      );
      this.getTotalAmount();
      this.spinner.hide();
    });
  }

  
  getAllCategoryData(): void {
    this.spinner.show();
    this.auth.get("categories").subscribe((response: any) => {
      this.searchCategory = response.data;
      // this.expenseList = response.data;
      // this.searchExpenses = this.yearExpenseList = response.data.filter(
      //   (ele) =>
      //     new Date(ele.created_at).getFullYear() === new Date().getFullYear()
      // );
      // this.getTotalAmount();
      this.spinner.hide();
    });
  }

  viewCategory() {
    throw new Error('Method not implemented.');
    }
    
  /**
   * It filters the expense list based on the date from and date to and then filters the searched list for the selected
   * category
   * @param {Event} $event - Event - The event object that is passed to the function.
   */
  onIntervalSubmit($event: Event) {
    $event.preventDefault();
    const fromVal = $("#from").val();
    const toVal = $("#to").val();

    if (fromVal !== "" && toVal !== "") {
      this.searchVal = true;
      this.loading = true;

      const from = new Date(new Date(fromVal).toDateString());
      const to = new Date(new Date(toVal).toDateString());

      /* Filtering the expense list based on the date from and date to. */
      const searchedList = this.expenseList.filter((ele) => {
        const createdAt = new Date(new Date(ele.created_at).toDateString());
        return (
          (isEqual(from, createdAt) || isAfter(createdAt, from)) &&
          (isEqual(to, createdAt) || isBefore(createdAt, to))
        );
      });

      /* Filtering searched list for the selected category */
      this.searchExpenses =
        this.selectedCategory === "All Categories"
          ? searchedList
          : searchedList.filter(
              (ele) => ele.category === this.selectedCategory
            );

      this.searchedExpressList = searchedList;
      this.getTotalAmount();
      this.loading = false;
    } else {
      this.generalService.emptyDateFieldError();
    }
  }

  /**
   * The function clears the search fields and resets the searchExpenses array to the yearExpenseList array
   */
  clear() {
    $("#from").val("");
    $("#to").val("");
    this.searchVal = false;
    this.searchExpenses = this.yearExpenseList;
    this.getTotalAmount();
  }

  addNewExpense() {
    this.addExpenseModal.show();
  }

  addNewCategory() {
    this.addCategoryModal.show();
  }

  onCategorySubmit() {
    const category = $("#addCategory").val();
    if (category !== "") {
      this.spinner.show();
      this.auth.store("categories", { category_name: category }).subscribe(
        (response: any) => {
          if (response !== null || true) {
            this.spinner.hide();
            this.addCategoryModal.hide();
            this.alert.success(response.message);
            $("#addCategory").val("");
            this.getCategories();
            Swal.fire({
              title: 'Submitted Successfully',
              icon: 'success',
              showConfirmButton: false,
              timer: 2500
            });
          }
        },
        (error) => {
          this.spinner.hide();
          if (error.status === 500) {
            this.alert.warning("Internal Server Error");
          } else if (error.status === 651) {
            this.alert.warning("Please check your connection");
          } else {
            this.alert.error("Can`t create a request now. Try again later");
          }
        }
      );
    } else {
      this.generalService.errorAlert(
        "A category name must be provided. Please check and try again"
      );
    }
  }

  createExpenseCard() {
    this.spinner.hide();
    this.setData();
    // console.log(this.itemDetails);
   this.auth.store("expenses", this.expenseDetails).subscribe(
      (response) => {
        // console.log(response);
        if (response !== null || response !== undefined) {
          this.getExpenses();
          this.alert.success(response["message"]);
          this.spinner.hide();
        }
      },
      (error) => {
        // console.log(error);
        this.spinner.hide();
        if (error.status === 500) {
            this.alert.warning("Internal Server Error");
          } else if (error.status === 651) {
              this.alert.warning("Please check your connection");
          } else {
            this.alert.error("Can`t create a request now. Try again later");
          }
      }
    );
  }

  createCategoryCard() {
    this.spinner.hide();
    this.setCatData();
    // console.log(this.itemDetails);
   this.auth.store("categories", this.categoryDetails).subscribe(
      (response) => {
        // console.log(response);
        if (response !== null || response !== undefined) {
          this.getCategories();
          this.alert.success(response["message"]);
          this.spinner.hide();
        }
      },
      (error) => {
        // console.log(error);
        this.spinner.hide();
        if (error.status === 500) {
            this.alert.warning("Internal Server Error");
          } else if (error.status === 651) {
              this.alert.warning("Please check your connection");
          } else {
            this.alert.error("Can`t create a request now. Try again later");
          }
      }
    );
  }

  editExpenseCard() {
    this.spinner.hide();
    this.setEditData();
    // console.log(this.itemEditDetails);
    this.auth
      .update("expenses", this.editExpenses.id, this.expenseEditDetails)
      .subscribe(
        (response) => {
          // console.log(response);
          if (response !== null || response !== undefined) {
            this.getExpenses();
            this.alert.success(response["message"]);
            this.spinner.hide();
          }
        },
        (error) => {
          // console.log(error);
          this.spinner.hide();
          if (error.status === 500) {
            this.alert.warning("Internal Server Error");
          } else if (error.status === 651) {
              this.alert.warning("Please check your connection");
          } else {
            this.alert.error("Can`t create a request now. Try again later");
          }
        }
      );
  }

  editCategoryCard() {
    this.spinner.hide();
    this.setCatEditData();
    // console.log(this.itemEditDetails);
    this.auth
      .update("categories", this.editCategory.id, this.categoryEditDetails)
      .subscribe(
        (response) => {
          // console.log(response);
          if (response !== null || response !== undefined) {
            this.getCategories();
            this.alert.success(response["message"]);
            this.spinner.hide();
          }
        },
        (error) => {
          // console.log(error);
          this.spinner.hide();
          if (error.status === 500) {
            this.alert.warning("Internal Server Error");
          } else if (error.status === 651) {
              this.alert.warning("Please check your connection");
          } else {
            this.alert.error("Can`t create a request now. Try again later");
          }
        }
      );
  }

  onAddExpenseSubmit() {
    const category = $("#selCategory").val();
    const expense = $("#expense").val();
    const amount = $("#amount").val();

    if (category !== "" && expense !== "" && amount !== "") {
      this.spinner.show();
      this.auth
        .store("expenses", {
          category,
          expense,
          amount: parseFloat(amount),
        })
        .subscribe(
          (response: any) => {
            if (response !== null || true) {
              this.spinner.hide();
              this.addExpenseModal.hide();
              this.alert.success(response.message);
              $("#selCategory").val("");
              $("#expense").val("");
              $("#amount").val("");
              this.getExpenses();
              Swal.fire({
                title: 'Submitted Successfully',
                icon: 'success',
                showConfirmButton: false,
                timer: 2500
              });
            }
          },
          (error) => {
            this.spinner.hide();
            if (error.status === 500) {
              this.alert.warning("Internal Server Error");
            } else if (error.status === 651) {
              this.alert.warning("Please check your connection");
            } else {
              this.alert.error("Can`t create a request now. Try again later");
            }
          }
        );
    } else {
      this.generalService.errorAlert(
        "All fields must be filled. Please check and try again"
      );
    }
  }

  /**
   * It filters the expenses based on the category selected
   * @param value - The value of the selected option.
   */
  onCategoryChange(value) {
    if (this.searchVal) {
      this.searchExpenses =
        value === "All Categories"
          ? this.searchedExpressList
          : this.searchedExpressList.filter((ele) => ele.category === value);
    } else {
      this.searchExpenses =
        value === "All Categories"
          ? this.yearExpenseList
          : this.yearExpenseList.filter((ele) => ele.category === value);
    }
    this.selectedCategory = value;
    this.getTotalAmount();
  }

  getTotalAmount(): void {
    let amount = 0;

    for (const expense of this.searchExpenses) {
      amount += expense.amount;
    }

    this.totalAmount = amount;
  }

  view(id) {
    this.spinner.show();
    this.auth.show("expenses", id).subscribe(
      (response) => {
        console.log(response["data"]);
        this.viewExpenses = response["data"];
        console.log("VIEW EXPENSES", this.viewExpenses)
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        // console.log(error);
        this.alert.warning("Could not get requested data");
      }
    );
  }

  delete(expense_id) {
    // Set the default alert options
    let alertObject: SweetAlertOptions = {
      title: "Are you sure?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
    };
    // Sets the alert text
    alertObject.text = "This record will be permanently deleted?";

    Swal.fire(alertObject).then((willDelete) => {
      if (!willDelete.dismiss) {
        Swal.fire({
          text: "Record has been deleted!",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });

        this.softDeleteItem(expense_id);
      }
    });
  }
  
  softDeleteItem(id) {
    this.spinner.show();
    this.auth.destroy("expenses", id).subscribe(
      (response) => {
        this.alert.success(response["message"]);
        console.log("DELETE", response)
        this.getExpenses();
        this.spinner.hide();
      },
      (error) => {
        // console.log(error);
        this.spinner.hide();
        this.alert.error("Error deleting data, please try again.");
      }
    );
  }
  deleteCat(category_id) {
    // Set the default alert options
    let alertObject: SweetAlertOptions = {
      title: "Are you sure?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
    };
    // Sets the alert text
    alertObject.text = "This record will be permanently deleted?";

    Swal.fire(alertObject).then((willDelete) => {
      if (!willDelete.dismiss) {
        Swal.fire({
          text: "Record has been deleted!",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });

        this.softDeleteCat(category_id);
      }
    });
  }
  
  softDeleteCat(id) {
    this.spinner.show();
    this.auth.destroy("categories", id).subscribe(
      (response) => {
        this.alert.success(response["message"]);
        console.log("DELETE", response)
        this.getCategories();
        this.spinner.hide();
      },
      (error) => {
        // console.log(error);
        this.spinner.hide();
        this.alert.error("Error deleting data, please try again.");
      }
    );
  }
  
  editCat(id) {
    this.spinner.hide();
    this.auth.edit("categories", id).subscribe(
      (response) => {
        // console.log(response);
        this.editCategory = response["data"];
        this.prepareCatEditForm();
        // if (this.editItem.for_rectal_tumor === 1) {
        //   this.specimen = true;
        // } else {
        //   this.specimen = false;
        // }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        // console.log(error);
        this.alert.error("Could not update info, please try again later");
      }
    );
  }
  edit(id) {
    this.spinner.show();
    this.auth.edit("expenses", id).subscribe(
      (response) => {
        // console.log(response);
        this.editExpenses = response["data"];
        this.prepareEditForm();
        // if (this.editItem.for_rectal_tumor === 1) {
        //   this.specimen = true;
        // } else {
        //   this.specimen = false;
        // }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        // console.log(error);
        this.alert.error("Could not update info, please try again later");
      }
    );
  }

    // Get company profile picture
    getComProfile() {
      this.spinner.show();
      this.auth.get("facility_profile").subscribe(
        (response) => {
          if (response["company"] !== null) {
            this.comData = response["company"];
            this.spinner.hide();
          } else {
            // this.alert.info('Response was null');
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          // console.log(error);
        }
      );
    }
    
    // Print to PDF function
    printMe() {
      printElement(document.getElementById("printThis"));
      function printElement(elem) {
        const domClone = elem.cloneNode(true);
  
        // const $printSection = document.getElementById("printSection");
  
        // if (!$printSection) {
          const $printSection = document.createElement("div");
          $printSection.id = "printSection";
          document.body.appendChild($printSection);
        // }
  
        $printSection.innerHTML = "";
        $printSection.appendChild(domClone);
        window.print();
        $printSection.innerHTML = "";
      }
    }
}
