import { UtilityService } from "./../../../../../others/services/utility.service";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NavigationItem } from "../navigation";
import { NextConfig } from "../../../../../app-config";
import { Location } from "@angular/common";
import { AuthenticationService } from "src/app/others/services/authentication.service";
import { AlertService } from "src/app/others/services/alert.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { UserroutesService } from "src/app/others/services/userroutes.service";

import { NavigateService } from "../navigate.service";
@Component({
  selector: "app-nav-content",
  templateUrl: "./nav-content.component.html",
  styleUrls: ["./nav-content.component.scss"],
})
export class NavContentComponent implements OnInit, AfterViewInit {
  id: string;
  username: string;
  user: any;
  // navOne = navAdmin;

  public nextConfig: any;
  public navigation: any;
  public prevDisabled: string;
  public nextDisabled: string;
  public contentWidth: number;
  public wrapperWidth: any;
  public scrollWidth: any;
  public windowWidth: number;
  public isNavProfile: boolean;

  @Output() onNavMobCollapse = new EventEmitter();

  @ViewChild("navbarContent", { static: false }) navbarContent: ElementRef;
  @ViewChild("navbarWrapper", { static: false }) navbarWrapper: ElementRef;
  admin: any;
  clinicalModules: any;
  patient: any;
  childNav: any;
  staff: any;
  doctors: any;
  nurses: any;
  pharmacist: any;
  billing: any;
  calendar: any;
  keyMetrics: any;
  clientM: any;
  smsSettings: any;
  facilityProfile: any;
  users: any;

  constructor(
    public nav: NavigationItem,
    private zone: NgZone,
    private location: Location,
    public auth: AuthenticationService,
    private alert: AlertService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private userPermission: UserroutesService,
    private navCon: NavigateService,
    private utility: UtilityService
  ) {
    this.nextConfig = NextConfig.config;
    this.windowWidth = window.innerWidth;

    this.navigation = this.nav.get();
    this.prevDisabled = "disabled";
    this.nextDisabled = "";
    this.scrollWidth = 0;
    this.contentWidth = 0;

    this.isNavProfile = false;
  }


  ngOnInit() {
    this.navCon.actionSideBar.subscribe((a) => {
      this.navigation = a
    })
    console.log("Navigation",this.navigation)

    // send various accesss and view status to navigate service
    this.userPermission.getPermission().subscribe({
      next: (response) => {
        // console.log(response);
        this.admin = response["permissions"]["permissions"][0].admin?.view;
        this.staff = response["permissions"]["permissions"][0].staff?.view;
        this.doctors = response["permissions"]["permissions"][0].doctors?.view;
        this.nurses = response["permissions"]["permissions"][0].nurses?.view;
        this.pharmacist = response["permissions"]["permissions"][0].pharmacist?.view;

        this.patient = response["permissions"]["permissions"][0].patients?.access;
        this.clinicalModules = response["permissions"]["permissions"][0].clinicalModules?.access;
        this.calendar =
          response["permissions"]["permissions"][0].calendar?.access;

        this.billing = response["permissions"]["permissions"][0].billing?.access;

        this.keyMetrics = response["permissions"]["permissions"][0].keyMetrics?.access;

        this.clientM = response["permissions"]["permissions"][0].clientM?.access;

        this.smsSettings = response["permissions"]["permissions"][0].smsSettings?.access;

        this.facilityProfile = response["permissions"]["permissions"][0].facilityProfile?.access;

        this.users = response["permissions"]["permissions"][0].users?.access;

        this.navCon.getUrl(this.admin, this.clinicalModules, this.billing, this.staff, this.patient, this.calendar, this.keyMetrics, this.clientM, this.smsSettings, this.facilityProfile , this.users, this.doctors, this.nurses, this.pharmacist )

      },
    });

    this.id = sessionStorage.getItem("userID");
    this.getUser();
    if (this.windowWidth < 992) {
      this.nextConfig["layout"] = "vertical";
      setTimeout(() => {
        document
          .querySelector(".pcoded-navbar")
          .classList.add("menupos-static");
        (
          document.querySelector("#nav-ps-next") as HTMLElement
        ).style.maxHeight = "100%";
      }, 500);
    }
  }

  //if admin is true take the patient url path out. That is the second item in the array
  // else take the first item which is the admin url.
  getUrlBaseOnPermission() {
    // console.log(this.navigation);

    // const nav = [
    //   {
    //     id: "other",
    //     title: "Menu",
    //     type: "group",
    //     icon: "feather icon-align-left",
    //     children: [
    //       {
    //         id: "admin-dashboard",
    //         title: "Admin Dashboard",
    //         type: "item",
    //         url: "/dashboard/admin",
    //         icon: "feather icon-activity",
    //       },
    //     ],
    //   },
    // ];

    // const navs = [
    //   {
    //     id: "other",
    //     title: "Menu",
    //     type: "group",
    //     icon: "feather icon-align-left",
    //     children: [
    //       {
    //         id: "dashboard",
    //         title: "Staff Dashboard",
    //         type: "item",
    //         url: "/dashboard/staff",
    //         icon: "feather icon-activity",
    //       },
    //     ],
    //   },
    // ];

    // const navs_clinical = [
    //   {
    //     id: "other",
    //     title: "Menu",
    //     type: "group",
    //     icon: "feather icon-align-left",
    //     children: [
    //       {
    //         id: "admin-dashboard",
    //         title: "Admin Dashboard",
    //         type: "item",
    //         url: "/dashboard/admin",
    //         icon: "feather icon-activity",
    //       },
    //       {
    //         id: "clinical-modules",
    //         title: "Clinical Modules",
    //         type: "collapse",
    //         icon: "feather icon-folder",
    //       }
    //     ],
    //   },
    // ];
    // if (this.admin && this.clinicalModules) {
    //   //  this.navigation[0].children.splice(1, 1)
    //   //  this.applyAllPermisiions()
    //   this.navigation = adminClinical;
    // }
    // if (this.admin && this.clinicalModules && this.billing) {
    //   this.navigation = admin_bill_clinic;
    // } else if (this.staff) {
    //   this.navigation = staffAmin;
    //   // this.navigation[0].children.shift();
    //   // this.applyAllPermisiions()
    // } else if (this.admin) {
    //   this.navigation = navAdmin;
    // }

    // if(!this.admin) {
    //   //  this.navigation[0].children.splice(1, 1)
    //    this.navigation[0].children.shift();
    //   //  this.applyAllPermisiions()
    // } else if(!this.staff) {
    //   this.navigation[0].children.splice(1, 1)
    //   // this.applyAllPermisiions()
    // } else if(!this.patient) {
    //   this.navigation[0].children.splice(2, 1)
    //   this.navigation[0].children.splice(3, 1)
    // } else if(!this.clinicalModules) {
    //   this.navigation[0].children.splice(4, 1)
    // }
    // console.log(this.navigation);
  }

  //.............................................................. programmer added ...........................................................
  getUser() {
    this.auth.show("user", this.id).subscribe(
      (response) => {
        this.user = response["data"];
        // console.log(response["data"]);
        // sessionStorage.removeItem("username");
        // sessionStorage.setItem("username", this.user.othernames);
        this.username = sessionStorage.getItem("username");
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  // logout method
  // logout() {
  //   this.auth.logout();
  //   sessionStorage.clear();
  //   // console.log("Logout success");
  //   this.router.navigate(["/auth/signin/"]);
  // }

  logout() {
    this.spinner.show();
    this.auth.logout().subscribe(
      (res) => {
        // this.utils.resetProfile();
        // console.log(res);
        // this.utility.stopTimer();
        sessionStorage.clear();
        this.router.navigate(["/auth/signin/"]);
        // Swal.fire('', res['message'], 'success');
        this.alert.success(res["message"]);
        this.spinner.hide();

        this.utility.stopTimer();
      },
      (err) => {
        // console.log(err);
        this.spinner.hide();
        this.alert.error("Could not log out, please try again");
      }
    );
  }

  checkLogin() {
    this.auth.isLoggedIn();
  }
  //.............................................................. programmer added ...........................................................

  ngAfterViewInit() {
    if (this.nextConfig["layout"] === "horizontal") {
      this.contentWidth = this.navbarContent.nativeElement.clientWidth;
      this.wrapperWidth = this.navbarWrapper.nativeElement.clientWidth;
    }
  }

  scrollPlus() {
    this.scrollWidth = this.scrollWidth + (this.wrapperWidth - 80);
    if (this.scrollWidth > this.contentWidth - this.wrapperWidth) {
      this.scrollWidth = this.contentWidth - this.wrapperWidth + 80;
      this.nextDisabled = "disabled";
    }
    this.prevDisabled = "";
    if (this.nextConfig.rtlLayout) {
      (
        document.querySelector("#side-nav-horizontal") as HTMLElement
      ).style.marginRight = "-" + this.scrollWidth + "px";
    } else {
      (
        document.querySelector("#side-nav-horizontal") as HTMLElement
      ).style.marginLeft = "-" + this.scrollWidth + "px";
    }
  }

  scrollMinus() {
    this.scrollWidth = this.scrollWidth - this.wrapperWidth;
    if (this.scrollWidth < 0) {
      this.scrollWidth = 0;
      this.prevDisabled = "disabled";
    }
    this.nextDisabled = "";
    if (this.nextConfig.rtlLayout) {
      (
        document.querySelector("#side-nav-horizontal") as HTMLElement
      ).style.marginRight = "-" + this.scrollWidth + "px";
    } else {
      (
        document.querySelector("#side-nav-horizontal") as HTMLElement
      ).style.marginLeft = "-" + this.scrollWidth + "px";
    }
  }

  fireLeave() {
    const sections = document.querySelectorAll(".pcoded-hasmenu");
    for (let i = 0; i < sections.length; i++) {
      sections[i].classList.remove("active");
      sections[i].classList.remove("pcoded-trigger");
    }

    let current_url = this.location.path();
    if (this.location["_baseHref"]) {
      current_url = this.location["_baseHref"] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains("pcoded-hasmenu")) {
        parent.classList.add("active");
      } else if (up_parent.classList.contains("pcoded-hasmenu")) {
        up_parent.classList.add("active");
      } else if (last_parent.classList.contains("pcoded-hasmenu")) {
        last_parent.classList.add("active");
      }
    }
  }

  navMob() {
    if (
      this.windowWidth < 992 &&
      document
        .querySelector("app-navigation.pcoded-navbar")
        .classList.contains("mob-open")
    ) {
      this.onNavMobCollapse.emit();
    }
  }

  fireOutClick() {
    let current_url = this.location.path();
    if (this.location["_baseHref"]) {
      current_url = this.location["_baseHref"] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains("pcoded-hasmenu")) {
        if (this.nextConfig["layout"] === "vertical") {
          parent.classList.add("pcoded-trigger");
        }
        parent.classList.add("active");
      } else if (up_parent.classList.contains("pcoded-hasmenu")) {
        if (this.nextConfig["layout"] === "vertical") {
          up_parent.classList.add("pcoded-trigger");
        }
        up_parent.classList.add("active");
      } else if (last_parent.classList.contains("pcoded-hasmenu")) {
        if (this.nextConfig["layout"] === "vertical") {
          last_parent.classList.add("pcoded-trigger");
        }
        last_parent.classList.add("active");
      }
    }
  }
}
