<div class="row mb-4 align-items-center" style="margin-top: -130px" *ngIf="permissions.performance.view ">
  <div class="col-lg-6">
    <h3 class="">Performance</h3>
  </div>
  <div class="col-lg-6 col-md-6 text-right">
    <button (click)="onTabChange('pathology')"
    [ngClass]="tab === 'pathology' ? 'btn-danger' : 'btn-outline-danger'" class="btn btn-round font-weight-bold text-uppercase mr-2">
      Pathology
    </button>
    <button (click)="onTabChange('radiology')"
            [ngClass]="tab === 'radiology' ? 'btn-danger' : 'btn-outline-danger'" class="btn btn-round font-weight-bold text-uppercase mr-2">
      Radiology
    </button>
    <button (click)="onTabChange('clinical_lab')"
            [ngClass]="tab === 'clinical_lab' ? 'btn-danger' : 'btn-outline-danger'" class="btn btn-round font-weight-bold text-uppercase mr-2">
            Clinical Lab
    </button>
  </div>
</div>

<div class="card" *ngIf="permissions.performance.view ">
  <div class="card-header py-3">
    <div class="row">
      <div class="col">
        <h4>Interval</h4>
      </div>
      <div class="col text-right">
        <p class="mb-0 small">Current Data</p>
        <h5 class="mr-0" *ngIf="!searchVal">All time</h5>
        <div *ngIf="searchVal && newStartDate && newEndDate"> From:
          {{newStartDate}} - To: {{newEndDate}}
        </div> 
      </div>

      <!-- <div class="col text-right d-flex-inline">
        <p class="mb-0 small">Current Data</p>
        <div *ngIf="newStartDate && newEndDate">
          {{newStartDate}} to {{newEndDate}}
        </div> 
        <h5 class="mr-0">All time</h5>
      </div> -->

    </div>
  </div>

    <div class="card-body">
    <div class="row">
    <div class="col-md-6 col-sm-12">
      <input placeholder="Click to enter date" required type="text"
      class="form-control" type="date" [(ngModel)]="newStartDate" />
      <label>From:</label>
    </div>
    <div class="col-md-6 col-sm-12">
      <input placeholder="Click to enter date" required type="text"
      class="form-control" type="date" [(ngModel)]="newEndDate" />
      <label>To:</label>
    </div>
    </div>
    <div class="col-md-12 col-sm-12 d-flex justify-content-center">
      <button (click)="getInputDate()"
      [disabled]="false"
      class="btn btn-success"
      style="padding:10px 15%;"
      type="submit">

      <span class="spinner-border-sm" role="status"></span>
      Search
    </button>
  </div>
  <div class="col text-right" *ngIf="searchVal">
    <button (click)="clear()" class="btn btn-danger btn-sm"
            type="button">
      Clear
    </button>
  </div>
  <!-- <div [ngClass]="searchCal ? 'd-flex-inline' : 'd-none'" class="col text-right">
    <button (click)="clear()" class="btn btn-danger btn-sm">
      Clear
    </button>
  </div> -->
</div>
  <!-- </div> -->
    <!-- <div class="card-body">
    <form (submit)="onIntervalSubmit($event)" action="">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="from" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="from">From:</label>
        </div>
        <div class="col-md-6 col-sm-12">
          <input class="form-control" id="to" onblur="(this.type='text')"
                 onfocus="(this.type='date')"
                 placeholder="Click to enter date" required type="text">
          <label for="to">To:</label>
        </div>
        <div class="col-md-12 col-sm-12 d-flex justify-content-center">
          <button [disabled]="false"
                  class="btn btn-success"
                  style="padding:10px 15%;"
                  type="submit">

            <span *ngIf="this.loading" class="spinner-border spinner-border-sm" role="status"></span>
            Search
            <span *ngIf="this.loading" class="load-text">ing...</span>
          </button>
        </div>
      </div>
    </form>
  </div> -->
</div>

<div class="row justify-content-center" *ngIf="permissions.performance.view ">
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header bg-billed text-center border-top-radius" style="background-color: rgb(25, 150, 25);">
        <h5 class="">Total Amount billed</h5>
      </div>
      <div class="card-body text-center">
        <h5>{{ currencyBase }} {{ calculatedTotalBilled | number: "1.2" }}</h5>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header bg-paid text-center border-top-radius" style="background-color: rgb(37, 37, 233);">
        <h5 class="">Total Paid</h5>
      </div>
      <div class="card-body text-center">
        <h5>{{ currencyBase }} {{calculatedTotalPaid | number: "1.2"}}</h5>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header bg-arrears text-center border-top-radius" style="background-color: rgb(233, 38, 38);">
        <h5 class="">Total Arrears</h5>
      </div>
      <div class="card-body text-center">
        <h5>{{ currencyBase }} {{calculatedTotalArreas | number: "1.2"}}</h5>
      </div>
    </div>
  </div>
</div>

<!-- <div>
  {{}}
</div> -->
<div class="card" *ngIf="permissions.performance.view ">
  <div class="card-header py-3">
    <h4>Service Cluster </h4>
    <!-- <span><i class="fas fa-info-circle" id="info-icon" style="color: #4680ff;"></i></span> -->
    <!-- <div class="container" id="info-modal" style="width: 300px; height: 60px;">
      <div class="">
        <p><b> Unassigned services not computed</b></p>
      </div>
    </div> -->
  </div>

  <div class="card-body">
    <table class="table table-bordered table-striped mb-0" id="report-table">
      <thead>
      <tr>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Service Cluster</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Billed</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Paid</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Arrears</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let cluster of getAllServiceClusters">
        <th> {{cluster.service_type}}</th>
        <td>{{currencyBase }}{{cluster.total_amount | number: "1.2"}}</td>
        <td>{{ currencyBase }}{{cluster.amount_paid | number: "1.2"}}</td>
        <td>{{ currencyBase }} {{cluster.arrears | number: "1.2"}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- <div>
  {{detailedItem | json}}

</div> -->

<div class="card" *ngIf="permissions.performance.view ">
  <div class="card-header py-3">
    <h4>Details</h4>
    <div class="row">
    </div>
  </div>
  <div class="card-body">
    <table class="table table-bordered table-striped mb-0" id="report-table">
      <thead>
      <tr>
        <!-- <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> -->
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Specimen</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Cases</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Billed</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Paid</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Arrears</th>
        <th style="padding-left: 10px; padding-right: 10px; text-transform: none">Action</th>
        <!-- <th (click)="changeOrder()" class="cursor" style="padding-left: 10px; padding-right: 10px" width="5%">total tat -->
        <!-- <span [ngClass]="desc ? 'fa-arrow-circle-o-up' : 'fa-arrow-circle-o-down'" class="fa "></span></th> -->
      </tr>
      </thead>
      <tbody>
      <!-- <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> -->
      <tr *ngFor="let spec of detailedItem | paginate: { itemsPerPage: 10, currentPage: p };">

        <!-- <th> {{spec.specimen.replace("Form", "").trim()}}</th> -->
        <!-- <td>
          <span *ngIf="spec.specimen === 'General Radiology Specimens'; else new">
              <p>General Radiology Specimen</p>
          </span>
          <span #new>
                    <th> {{spec.specimen | nameReplace }}</th>

          </span>
        </td> -->
        <td *ngIf="spec.cases !== 0">
          <!-- <span *ngIf="spec.specimen === 'General Radiology Specimens'; else new">
              <p>General Radiology Specimen</p>
          </span> -->
          <span #new>
                    <b> {{spec.form_name | nameReplace }}</b>
          </span>
        </td>
        <td *ngIf="spec.cases !== 0">{{spec.cases}}</td>
        <td *ngIf="spec.cases !== 0" >{{ currencyBase }}{{spec.total_amount | number: "1.2"}}</td>
        <td *ngIf="spec.cases !== 0">{{ currencyBase }} {{spec.amount_paid  | number: "1.2"}}</td>
        <td *ngIf="spec.cases !== 0">{{ currencyBase }}{{spec.arreas | number: "1.2"}}</td>
        <!-- <td>{{ currencyBase }}{{getBilled(spec.reports) | number: "1.2"}}</td>
        <td>{{ currencyBase }} {{getPaid(spec.reports) | number: "1.2"}}</td>
        <td>{{ currencyBase }}{{getArrears(spec.reports) | number: "1.2"}}</td> -->
        <td *ngIf="spec.cases !== 0">
          <button (click)="view(spec.spec_details)" [placement]="'top'" class="btn btn-success btn-sm" data-backdrop="static"
                  id="viewBtn" ngbTooltip="VIEW">
            <i class="feather icon-eye"></i> View
          </button>
        </td>

      </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer pb-0">
    <pagination-controls
      (pageBoundsCorrection)="p = $event"
      (pageChange)="p = $event"
      autoHide="false"
      directionLinks="true"
      maxSize="10"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: right"
    >
    </pagination-controls>
  </div>
</div>

<!-- -------------------------------------------------------------------------------- VIEW --------------------------------------------------------------------------------------- -->
<app-ui-modal #modalView [containerClick]="false" [dialogClass]="'modal-xl btn-page'" class="doNotPrint">
  <div class="app-modal-header">
    <h4 class="modal-title" style="color:#0873ee;text-transform:uppercase">Performance Details</h4>
  </div>
  <div class="app-modal-header">
    <button (click)="printElement(); modalView.hide();" class="btn btn-secondary" type="button">Print</button>&nbsp;
    <button  (click)="modalView.hide()" class="btn btn-danger" data-dismiss="modal" type="button">Close</button>
  </div>
  <div class="app-modal-body main-look">
    <div id="contentToPrint">
      <div class="row mb-4">
        <div class="col-sm-12">
          <img *ngIf="companyData?.company_logo !== null" alt=""
               src="{{ companyData?.company_logo }}" style="width: 100%; height: 160px;">
        </div>
      </div>

      <div class="">
        <h3 class="mb-0">Specimen Performance Details</h3>
        <!-- <h4 class="">{{selectedSpecimen?.specimen.replace("Form", "").trim()}}</h4> -->
        <p class="mt-0"*ngIf="searchVal && newStartDate && newEndDate"> From:
          {{newStartDate}} - To: {{newEndDate}}</p>
        <hr>
      </div>
      <table class="table table-bordered table-striped doNotPrint">
        <thead>
        <tr>
          <!-- <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> -->
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Patient</th>
          <th style="padding-left: 10px; padding-right: 10px" width="5%">Billed</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Paid</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Arrears</th>
          <th style="padding-left: 10px; padding-right: 10px" width="15%">Date Created</th>
        </tr>
        </thead>
        <tbody>
        <!-- <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> -->
        <tr
          *ngFor="let rep of selectedSpecimen">
          <td><span>{{ rep.first_name + ' ' + rep.last_name}}</span></td>
          <!-- <td><span>₵{{rep.total_amount | number: "1.2"}}</span></td> -->
          <td><span>₵{{rep.total_amount ? rep.total_amount : 0 | number: "1.2"}}</span></td>
          <td><span>₵{{rep.amount_paid ? rep.amount_paid : 0 | number: "1.2"}}</span></td>
          <td><span>₵{{rep.arreas ? rep.arreas : 0 | number: "1.2"}}</span></td>
          <!-- <td><span>₵{{rep.arreas | number: "1.2"}}</span></td> -->
          <td><span
            style="font-size:inherit;color:#0a661f;font-weight:bold;">{{ rep.created_at | date: "MMM d, y":"GMT"}}</span>
          </td>
        </tr>
        </tbody>
      </table>

      <table class="table table-bordered table-striped toPrint">
        <thead>
        <tr>
          <!-- <th width="40%">{{ selectedDebtor?(selectedDebtor=='requesting facility'?'Facility':'Patient'):'Debtor' }}</th> -->
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Patient</th>
          <th style="padding-left: 10px; padding-right: 10px" width="5%">Billed</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Paid</th>
          <th style="padding-left: 10px; padding-right: 10px" width="25%">Arrears</th>
          <th style="padding-left: 10px; padding-right: 10px" width="15%">Date Created</th>
        </tr>
        </thead>
        <tbody>
        <!-- <tr *ngFor="let search of selectedDebtor?(selectedDebtor=='requesting facility'? results.facility:results.patient):''"> -->
        <tr *ngFor="let rep of selectedSpecimen">
          <td><span>{{ rep.patient_name }}</span></td>
          <td><span>₵{{rep.total_amount | number: "1.2"}}</span></td>
          <td><span>₵{{rep.amount_paid ? rep.amount_paid : 0 | number: "1.2"}}</span>
          </td>
          <td><span>₵{{rep.arreas | number: "1.2"}}</span>
          </td>
          <td><span
            style="font-size:inherit;color:#0a661f;font-weight:bold;">{{ rep.created_at | date: "MMM d, y":"GMT"}}</span>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
  <div class="app-modal-footer">
    <pagination-controls
      (pageBoundsCorrection)="detailPage = $event"
      (pageChange)="detailPage = $event"
      autoHide="false"
      directionLinks="true"
      id="details"
      maxSize="10"
      nextLabel="Next"
      previousLabel="Previous"
      responsive="true"
      screenReaderCurrentLabel="You're on page"
      screenReaderPageLabel="page"
      screenReaderPaginationLabel="Pagination"
      style="float: left"
    >
    </pagination-controls>
    <button (click)="modalView.hide();" class="btn btn-danger" type="button">Close</button>
  </div>
</app-ui-modal>
<!-- ------------------------------------------------------------------------------ END VIEW ------------------------------------------------------------------------------------- -->



