import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "../../../others/services/authentication.service";
import { NgxSpinnerService } from "ngx-spinner";
import { GeneralService } from "../../../services/general.service";
import { isAfter, isBefore, isEqual } from "date-fns";
import "jquery";

declare let $: any;

@Component({
  selector: "app-client-reports",
  templateUrl: "./client-reports.component.html",
  styleUrls: ["./client-reports.component.scss"],
})
export class ClientReportsComponent implements OnInit {
  @ViewChild("modalView") viewModal;

  searchedClientList: any[] = [];
  clientList: any[] = [];
  clientReportsList: any[] = [];
  generalServices: any;
  searchVal = false;
  loading = false;
  actionButtons = true;

  permissions: any = [];
  singleCurrency = "";
  currencyNme: any;
  currencySym: any;
  currencyBase: any;
  selectedClient: any;
  fromDate: any;
  toDate: any;
  newStartDate: any;
  newEndDate: any;
  companyData: any;
  p = 0;
  detailPage = 0;
  dtOptions: DataTables.Settings={};

  constructor(
    public auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private generalService: GeneralService
  ) {}

  ngOnInit() {
    this.getPermissions();
    this.getClientReports();
    this.generalServices = this.generalService;
    this.singleCurrency = this.userArray(sessionStorage.getItem("currency"));
    this.currencyNme = this.singleCurrency[0];
    this.currencySym = this.singleCurrency[1];
    this.currencyBase = this.singleCurrency[2];
    this.getComProfile();

    this.dtOptions={
      pagingType: "simple_numbers",
      pageLength: 10,
      lengthMenu: [10, 30, 50],
      processing: true,
      responsive: true,
      // "order": [[2, "desc"]],
    }
  }
 // Get user permissions
//  getPermissions() {
//   this.permissions = {
//     clients: {
//       view: false
//     },
//   };

//   this.auth
//     .get("user_permissions/" + sessionStorage.getItem("userID")).toPromise()
//     .then(
//       (response: any) => {
//         this.permissions = response.permissions.permissions[0];
//         this.actionButtons = Object.keys(this.permissions.clients).every((key) => {
//           return key == "create" ? false : this.permissions.clients[key];
//         })
//       }
//     );
// }

getPermissions() {
  this.permissions = {
    clients: {
      view: false,
      },
  };
  this.auth.get('user_permissions/' + sessionStorage.getItem('userID')).subscribe(
    (response: any) => {
      this.permissions = response.permissions.permissions[0];
    },
    (error: any) => {

    }
  );
}

  userArray(str) {
    return str.trim().split(",");
  }

  getClientReports() {
    this.spinner.show();
    this.auth.get("client_reports").subscribe((response: any) => {
      const a = [];
      for (const res of response) {
        a.push(...res);
      }

      this.searchedClientList = this.clientList = a;
      this.getClientList();
    });
    this.spinner.hide();
  }

  getClientList() {
    const lists = this.searchedClientList;
    const a = [];
    const b = [];

    for (const list of lists) {
      const client = list.requesting_facility;
      const doctor = list.requesting_doctor;
      if (!a.includes(client)) {
        const reports = lists.filter(
          (ele) => ele.requesting_facility === client
        );

        b.push({ client, reports, doctor });
        a.push(client);
      }
    }

    this.clientReportsList = b;
    console.log("Clients Report details", this.clientReportsList)
  }

  /**
   * The function clears the search fields and resets the search results to the original list
   */
  clear() {
    // $("#to").val("");
    // $("#from").val("");
    this.newStartDate = (" ");
    this.newEndDate = (" ");
    this.searchedClientList = this.clientList;
    this.searchVal = false;
    this.getClientList();
    this.getInputDate();
    // this.changeOrder();
  }  

  /**
   * It filters the staffList array to only include the elements that have a created_at date that is equal to or after the
   * from date and equal to or before the to date
   * @param {Event} $event - Event - The event object that is passed to the function when the form is submitted.
   */
  onIntervalSubmit($event: Event) {
    $event.preventDefault();
    const fromVal = $("#from").val();
    const toVal = $("#to").val();

    if (fromVal !== "" && toVal !== "") {
      this.searchVal = true;
      this.loading = true;

      /* Converting the date to a string and then back to a date. */
      const from = new Date(new Date(fromVal).toDateString());
      const to = new Date(new Date(toVal).toDateString());

      this.fromDate = from.toDateString();
      this.toDate = to.toDateString();

      /* Filtering the tatList array to only include the elements that have a created_at date that is equal to or after the
     from date and equal to or before the to date. */
      this.searchedClientList = this.clientList.filter((ele) => {
        const createdAt = new Date(new Date(ele.created_at).toDateString());
        return (
          (isEqual(from, createdAt) || isAfter(createdAt, from)) &&
          (isEqual(to, createdAt) || isBefore(createdAt, to))
        );
      });
      this.getClientList();
      this.loading = false;
    } else {
      this.generalService.emptyDateFieldError();
    }
  }

  view(selected: any) {
    this.selectedClient = selected;
    this.detailPage = 0;
    this.viewModal.show();
    console.log ("VIEW DATA", this.selectedClient.selected)
  }

  getInputDate() {
    if (this.newStartDate && !this.newEndDate) {
      this.searchVal = true;
      this.auth.get(`client_reports?start_date=${this.newStartDate}`).subscribe({
        next: (response: any) => {
          console.log("Start Date Only", response);
                const a = [];
      for (const res of response) {
        a.push(...res);
      }

      this.searchedClientList = this.clientList = a;
      this.getClientList();      
        }
      });
    } else 
    if (this.newEndDate && !this.newStartDate){
      this.searchVal = true;
      this.auth.get(`client_reports?end_date=${this.newEndDate}`).subscribe({
        next: (response: any) => {
          console.log("End Date Only", response);
                const a = [];
      for (const res of response) {
        a.push(...res);
      }

      this.searchedClientList = this.clientList = a;
      this.getClientList();      
        }
      });
    } else 
    if (this.newStartDate && this.newEndDate) {
      this.searchVal = true;
      this.auth.get(`client_reports?start_date=${this.newStartDate}&end_date=${this.newEndDate}`).subscribe({
        next: (response: any) => {
          console.log("Both Dates", response);
                const a = [];
      for (const res of response) {
        a.push(...res);
      }

      this.searchedClientList = this.clientList = a;
      this.getClientList();      
        }
      });
    } else {
      this.auth.get("client_reports").subscribe((response: any) => {
        const a = [];
        for (const res of response) {
          a.push(...res);
        }
  
        this.searchedClientList = this.clientList = a;
        this.getClientList();
      });
    }
  }

  /**
   * This function is used to get the company profile data from the database
   */
  getComProfile() {
    this.spinner.show();
    this.auth.get("facility_profile").subscribe(
      (response: any) => {
        if (response.company !== null) {
          this.companyData = response.company;
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  csvMe() {
    const csvFileData = [];

    /* Looping through the resData array and pushing the values of the properties of each object in the array into
      a new array called data. */
    let newdata = this.selectedClient?.reports
    console.log("New data", newdata);
    for (const res of newdata) {
      
      csvFileData.push({
        doctor: res?.requesting_doctor,
        specimen: res?.form_name,
        id: res?.case_id,
        billed: res?.total_amount,
        paid: res?.amount_paid,
        arrears: res?.arreas,
        date_created: res?.created_at,
      });
    }
    console.log("Client CSV List", csvFileData);
    // create CSV file data in an array
    let dataKey = null;
    let dataVal = null;

    dataVal = csvFileData.map((obj) => {
      dataKey = Object.keys(obj);
      return Object.values(obj);
    });

    // create a user-defined function to download CSV file
    let csv = `${dataKey}\n`;

    // merge the data with CSV
    dataVal.forEach((row) => {
      csv += row.join(",");
      csv += "\n";
    });
    // display the created CSV data on the web browser
    document.write(csv);
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    // provide the name for the CSV file to be downloaded
    const date = new Date().toLocaleDateString("en-GB");
    hiddenElement.download = `Client Report Details-[${date}].csv`;
    hiddenElement.style.visibility = "hidden";
    hiddenElement.style.display = "none";
    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    document.body.removeChild(hiddenElement);
    location.reload();
  }

  /**
   * We're creating a new div element, appending it to the body, copying the contents of the element we want to print into
   * the new div, printing the new div, and then removing the new div from the body
   */
  printElement() {
    const elem = document.getElementById("contentToPrint");
    const domClone = elem.cloneNode(true);

    const $printSection = document.createElement("div");
    $printSection.style.padding = "15px 5px";
    $printSection.id = "printSection";
    document.body.appendChild($printSection);

    $printSection.innerHTML = "";
    $printSection.appendChild(domClone);
    window.print();
    $printSection.style.padding = "0px";
    $printSection.innerHTML = "";
  }
}
